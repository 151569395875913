import { configureStore } from '@reduxjs/toolkit';
import questionnaireReducer from './slices/questionnaireSlice';
import authReducer from './slices/authSlice';

export const store = configureStore({
  reducer: {
    questionnaire: questionnaireReducer,
    auth: authReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: ['auth/setCredentials'],
        // Ignore these field paths in all actions
        ignoredActionPaths: ['payload.timestamp'],
        // Ignore these paths in the state
        ignoredPaths: ['auth.user']
      }
    })
});
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;