import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Progress } from 'reactstrap';
import logo from "../../assets/images/logo/1.jpg";
const PrintableReport = ({ report }) => {
  const { t } = useTranslation();
  const printRef = useRef();

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0,
    }).format(amount);
  };

  const formatDate = (date) => {
    return new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    }).format(date);
  };

  return (
    <div ref={printRef} className="max-w-[8.5in] mx-auto bg-white text-gray-800 print:mx-0 print:max-w-none print:w-full">
      {/* Header - designed to always start on a new page */}
      <div className="p-12 border-b-2 border-gray-200 print:break-before-page">
        <div className="text-center">
          <img src={logo} alt="Logo" className="mx-auto mb-8 w-24 h-24" />
          <h1 className="text-4xl font-bold text-gray-900 mb-3">{t('CareerAssessmentReport')}</h1>
          <p className="text-lg text-gray-600">{t('generatedOn')} {formatDate(new Date())}</p>
          <p className="mt-4 text-xl">
            {t('preparedFor')}: <strong>{report?.details?.name || 'User'}</strong>
          </p>
        </div>
      </div>

      {/* Table of Contents */}
      <div className="p-12 page-break-after-always">
        <h2 className="text-2xl font-bold mb-6 text-gray-900">{t('tableOfContents')}</h2>
        <ol className="space-y-2">
          <li className="flex items-center justify-between">
            <span>1. {t('profileSummary')}</span>
            <span className="border-b border-dotted border-gray-300 flex-grow mx-4"></span>
            <span>1</span>
          </li>
          <li className="flex items-center justify-between">
            <span>2. {t('topSkillsAssessment')}</span>
            <span className="border-b border-dotted border-gray-300 flex-grow mx-4"></span>
            <span>2</span>
          </li>
          <li className="flex items-center justify-between">
            <span>3. {t('recommendedCareerPaths')}</span>
            <span className="border-b border-dotted border-gray-300 flex-grow mx-4"></span>
            <span>3</span>
          </li>
          <li className="flex items-center justify-between">
            <span>4. {t('learningRecommendations')}</span>
            <span className="border-b border-dotted border-gray-300 flex-grow mx-4"></span>
            <span>4</span>
          </li>
          <li className="flex items-center justify-between">
            <span>5. {t('actionPlan')}</span>
            <span className="border-b border-dotted border-gray-300 flex-grow mx-4"></span>
            <span>5</span>
          </li>
        </ol>
      </div>

      {/* Profile Summary */}
      <section className="p-12 border-t print:break-before-page">
        <h2 className="text-3xl font-bold text-gray-900 mb-6">1. {t('profileSummary')}</h2>
        <div className="text-lg text-gray-700 leading-relaxed">
          {report?.details?.name ? 
            t('profileSummaryDescription', { name: report.details.name }) :
            t('profileSummaryDescription', { name: 'User' })
          }
        </div>
      </section>

      {/* Skills Assessment */}
      <section className="p-12 border-t print:break-before-page">
        <h2 className="text-3xl font-bold text-gray-900 mb-8">2. {t('topSkillsAssessment')}</h2>
        <div className="space-y-8">
          {report.summary.topSkills.primaryStrengths.map((skill, index) => (
            <div key={index} className="border rounded-lg p-6">
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-xl font-bold text-gray-900">{t(skill.name)}</h3>
                <span className="text-xl font-bold text-gray-700">{skill.score}%</span>
              </div>
              <div className="w-full bg-gray-200 h-2 mb-4">
                <div 
                  className="bg-gray-700 h-2"
                  style={{ width: `${skill.score}%` }}
                />
              </div>
              <div className="mt-4">
                <h4 className="font-bold mb-2">{t("developmentAreas")}:</h4>
                <ul className="list-disc pl-5">
                  {skill.developmentTips.map((tip, idx) => (
                    <li key={idx} className="text-gray-700 mb-1">{tip}</li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </section>

      {/* Career Paths */}
      <section className="p-12 border-t print:break-before-page">
        <h2 className="text-3xl font-bold text-gray-900 mb-8">3. {t("recommendedCareerPaths")}</h2>
        <div className="space-y-8">
          {report.details.recommendations.careers.map((career, index) => (
            <div key={index} className="border rounded-lg p-6">
              <div className="flex justify-between items-start mb-6">
                <div>
                  <h3 className="text-xl font-bold text-gray-900 mb-2">{t(career.title)}</h3>
                  <p className="text-gray-700">{t(career.description)}</p>
                </div>
                <div className="text-right">
                  <div className="text-2xl font-bold text-gray-900">{career.matchScore}%</div>
                  <div className="text-sm text-gray-600">{t('matchScore')}</div>
                </div>
              </div>
              <div className="grid grid-cols-3 gap-6 border-t pt-6">
                <div>
                  <div className="text-sm text-gray-600 mb-1">{t('marketDemand')}</div>
                  <div className="font-bold text-gray-900">{career.marketDemand}</div>
                </div>
                <div>
                  <div className="text-sm text-gray-600 mb-1">{t('growthPotential')}</div>
                  <div className="font-bold text-gray-900">{career.growthPotential}%</div>
                </div>
                <div>
                  <div className="text-sm text-gray-600 mb-1">{t('entryLevelSalary')}</div>
                  <div className="font-bold text-gray-900">{formatCurrency(career.salary?.entry)}</div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>

      {/* Learning Recommendations */}
      <section className="p-12 border-t print:break-before-page">
        <h2 className="text-3xl font-bold text-gray-900 mb-8">4. {t('learningRecommendations')}</h2>
        <div className="space-y-8">
          {report.summary.learningRecommendations.immediate.map((recommendation, index) => (
            <div key={index} className="border rounded-lg p-6">
              <h3 className="text-xl font-bold text-gray-900 mb-4">{t(recommendation.skill)}</h3>
              <div className="mb-4">
                <span className="font-bold">{t("priority")}:</span> {t(recommendation.priority)}
              </div>
              <h4 className="font-bold mb-3">{t("recommendedResources")}:</h4>
              <ul className="list-disc pl-5">
                {recommendation.resources.courses.map((course, idx) => (
                  <li key={idx} className="mb-3">
                    <div className="font-bold">{t(course.title)}</div>
                    <div className="text-gray-700">
                      {t(course.provider)} | {course.level} | {course.duration}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </section>

      {/* Action Plan */}
      <section className="p-12 border-t print:break-before-page">
        <h2 className="text-3xl font-bold text-gray-900 mb-8">5. {t('actionPlan')}</h2>
        <div className="space-y-6">
          {report.actionPlan.shortTerm.map((action, index) => (
            <div key={index} className="border rounded-lg p-6">
              <h3 className="text-xl font-bold text-gray-900 mb-3">{t(action.action)}</h3>
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <span className="font-bold">{t('timeframe')}:</span> {t(action.timeframe)}
                </div>
                <div>
                  <span className="font-bold">{t('expectedOutcome')}:</span> {t(action.outcome)}
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>

      {/* Footer */}
      <footer className="p-12 border-t mt-8 print:break-before-page">
        <div className="text-center">
          <p className="font-bold text-gray-900">{t('careerAssessmentReport')} - {t('confidential')}</p>
          <p className="text-gray-600 mt-2">
            {t('generatedOn')} {formatDate(new Date())}
          </p>
          <p className="text-sm text-gray-500 mt-4">
            {t('pageOf', { page: '%PAGE%', total: '%TOTAL%' })}
          </p>
        </div>
      </footer>
    </div>
  );
};

export default PrintableReport;