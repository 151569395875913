import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import I18NextContext from "../Helpers/I18NextContext";
import ConvertPermissionArr from "../utils/CustomFunctions/ConvertPermissionArr";
import { replacePath } from "../utils/CustomFunctions/ReplacePath";
import Header from "./Header";
import Sidebar from "./Sidebar";

const AdminLayout = ({ lng }) => {
  const [darkMode, setDarkMode] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const [mounted, setMounted] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [isNonMobile, setIsNonMobile] = useState(window.innerWidth >= 1024);

  // Handle window resize
  useEffect(() => {
    const handleResize = () => {
      const isLargeScreen = window.innerWidth >= 1024;
      setIsNonMobile(isLargeScreen);
      if (!isLargeScreen) {
        setIsSidebarOpen(false);
      }
    };

    handleResize(); // Initial check
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [darkMode]);

  useEffect(() => {
    if (mounted) {
      const account = JSON.parse(localStorage.getItem("account") || "{}");
      const securePaths = ConvertPermissionArr(account?.user?.permissions || []);
      const currentPath = replacePath(location.pathname.split("/")[2]);

      if (!securePaths.find((item) => item?.name === currentPath)) {
        navigate("/403");
      }
    }
  }, [mounted, location.pathname, navigate]);

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  return (

    <div className={`min-h-screen bg-background text-foreground ${darkMode ? 'bg-gray-900 text-white' : 'bg-gray-50 text-gray-900'}`}>
      <main className="container mx-auto px-4 py-8">
        <div className={`min-h-screen `}>
          <ToastContainer theme="colored" />

          <Header
            setMode={setDarkMode}
            toggleDarkMode={toggleDarkMode}
            settingData="settingData"
            isSidebarOpen={isSidebarOpen}
            setIsSidebarOpen={setIsSidebarOpen}
            isNonMobile={isNonMobile}
            darkMode={darkMode}
            className={`
          fixed top-0 right-0 left-0 z-50 shadow-sm
          transition-all duration-300
          ${isNonMobile && isSidebarOpen ? 'lg:pl-[250px]' : ''}
          ${darkMode ? 'dark:bg-gray-800' : 'bg-white'}
        `}
          />

          {/* Main Layout Container */}
          <div className="flex h-screen pt-16"> {/* Added pt-16 to account for header height */}
            {/* Sidebar */}
            <Sidebar
              drawerWidth={isNonMobile ? "250px" : "240px"}
              isSidebarOpen={isSidebarOpen}
              setIsSidebarOpen={setIsSidebarOpen}
              isNonMobile={isNonMobile}
              darkMode={darkMode}
              className={`
            fixed left-0 top-16 bottom-0 z-40
            ${darkMode ? 'bg-gray-900 text-white' : 'bg-white text-gray-900'}
            transition-all duration-300 ease-in-out
            ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'}
            ${isNonMobile ? 'shadow-sm' : 'shadow-lg'}
          `}
            />

            {/* Main Content */}
            <main className={`
          flex-1
          transition-all duration-300 ease-in-out
          ${darkMode ? 'bg-gray-900 text-white' : 'bg-gray-50 text-gray-900'}
          ${isNonMobile && isSidebarOpen ? 'lg:pl-[120px]' : ''}
        `}>
              <div className="container mx-auto lg:p-8 min-h-[calc(100vh-4rem)]">
                {/* Page Content Container */}
                <div className={` rounded-lg shadow-sm `}>
                  <Outlet />
                </div>
              </div>
            </main>

            {/* Mobile Overlay */}
            {!isNonMobile && isSidebarOpen && (
              <div
                className="fixed inset-0 z-30 transition-opacity bg-black opacity-50"
                onClick={() => setIsSidebarOpen(false)}
                aria-hidden="true"
              />
            )}
          </div>
        </div>
      </main>
    </div>
  );
};

export default AdminLayout;
