import React from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '../../ui/card';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { Cpu, HardDrive, Database, AlertTriangle } from 'lucide-react';
import { Badge } from 'components/ui/badge';

export const formatBytes = (bytes) => {
  const units = ['B', 'KB', 'MB', 'GB'];
  let value = bytes;
  let unitIndex = 0;
  while (value >= 1024 && unitIndex < units.length - 1) {
    value /= 1024;
    unitIndex++;
  }
  return `${value.toFixed(1)} ${units[unitIndex]}`;
};

export const MetricsDisplay = ({ metrics }) => {
  if (!metrics) return null;

  const { system, performance } = metrics;

  // Calculate memory usage percentage
  const memoryUsagePercent = ((system.memory.heapUsed / system.memory.heapTotal) * 100).toFixed(1);

  // Format error rates data for chart
  const errorRatesData = Object.entries(system.errorRates).map(([stage, data]) => ({
    name: stage,
    rate: data.rate,
    failed: data.failed,
    total: data.total
  }));

  return (
    <div className="space-y-6">
      {/* System Resources */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        {/* Memory Usage */}
        <Card>
          <CardHeader className="pb-2">
            <CardTitle className="flex items-center text-sm font-medium">
              <HardDrive className="w-4 h-4 mr-2" />
              Memory Usage
            </CardTitle>
          </CardHeader>
          <CardContent>
            <div className="space-y-2">
              <div className="flex justify-between">
                <span className="text-sm text-muted-foreground">Heap Used</span>
                <span>{formatBytes(system.memory.heapUsed)}</span>
              </div>
              <div className="flex justify-between">
                <span className="text-sm text-muted-foreground">Heap Total</span>
                <span>{formatBytes(system.memory.heapTotal)}</span>
              </div>
              <div className="flex justify-between">
                <span className="text-sm text-muted-foreground">Usage</span>
                <Badge variant={memoryUsagePercent > 80 ? 'destructive' : 'default'}>
                  {memoryUsagePercent}%
                </Badge>
              </div>
            </div>
          </CardContent>
        </Card>

        {/* CPU Usage */}
        <Card>
          <CardHeader className="pb-2">
            <CardTitle className="flex items-center text-sm font-medium">
              <Cpu className="w-4 h-4 mr-2" />
              CPU Usage
            </CardTitle>
          </CardHeader>
          <CardContent>
            <div className="space-y-2">
              <div className="flex justify-between">
                <span className="text-sm text-muted-foreground">User CPU</span>
                <span>{(system.cpu.user / 1000000).toFixed(2)}s</span>
              </div>
              <div className="flex justify-between">
                <span className="text-sm text-muted-foreground">System CPU</span>
                <span>{(system.cpu.system / 1000000).toFixed(2)}s</span>
              </div>
              <div className="flex justify-between">
                <span className="text-sm text-muted-foreground">Uptime</span>
                <span>{Math.floor(system.uptime / 60)}m {Math.floor(system.uptime % 60)}s</span>
              </div>
            </div>
          </CardContent>
        </Card>

        {/* Database Status */}
        <Card>
          <CardHeader className="pb-2">
            <CardTitle className="flex items-center text-sm font-medium">
              <Database className="w-4 h-4 mr-2" />
              Database Status
            </CardTitle>
          </CardHeader>
          <CardContent>
            <div className="space-y-2">
              <div className="flex justify-between">
                <span className="text-sm text-muted-foreground">Connections</span>
                <span>{performance.metrics.database.connections}</span>
              </div>
              <div className="flex justify-between">
                <span className="text-sm text-muted-foreground">Open Connections</span>
                <span>{performance.metrics.database.openConnections}</span>
              </div>
              <div className="flex justify-between">
                <span className="text-sm text-muted-foreground">Total Queries</span>
                <span>{performance.metrics.database.totalQueries}</span>
              </div>
            </div>
          </CardContent>
        </Card>
      </div>

      {/* Error Rates Chart */}
      <Card>
        <CardHeader>
          <CardTitle className="flex items-center">
            <AlertTriangle className="w-4 h-4 mr-2" />
            Error Rates by Stage
          </CardTitle>
        </CardHeader>
        <CardContent>
          <div className="h-[300px]">
            <LineChart data={errorRatesData} width={800} height={300}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="rate" name="Error Rate %" stroke="#ef4444" />
              <Line type="monotone" dataKey="failed" name="Failed Count" stroke="#f97316" />
              <Line type="monotone" dataKey="total" name="Total Processed" stroke="#3b82f6" />
            </LineChart>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};