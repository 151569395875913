import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom'; // Using React Router for navigation
import useAuth from 'utils/Hooks/Auth/useAuth';


const ProtectedRoute = ({ children }) => {
  const isAuthenticated = useAuth();
  useEffect(() => {
console.log("isAuthenticated",isAuthenticated);
  
  }, [isAuthenticated])
  

  if (!isAuthenticated) {
    return <Navigate to="/admin/login" />;
  }

  return children;
};

export default ProtectedRoute;