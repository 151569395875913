import React, { useEffect } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '../../ui/card';
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { Activity, Clock, AlertCircle, Users, ArrowUpRight, ArrowDownRight, Timer } from 'lucide-react';

const MetricRow = ({ icon: Icon, label, value, subValue, colorClass = "text-blue-500" }) => (
  <div className="flex items-center space-x-4 p-2 hover:bg-muted/50 rounded-lg">
    <div className={`p-2 rounded-full bg-background border ${colorClass}`}>
      <Icon className="w-4 h-4" />
    </div>
    <div className="flex-1">
      <p className="text-sm font-medium text-muted-foreground">{label}</p>
      <div className="flex items-baseline gap-2">
        <p className="text-2xl font-bold">{value}</p>
        {subValue && (
          <p className="text-sm text-muted-foreground">{subValue}</p>
        )}
      </div>
    </div>
  </div>
);

const StageMetrics = ({ stage, metrics }) => {
  if (!metrics) return null;

  // Process historical data for the chart
  const chartData = (metrics.history || []).map(point => ({
    time: new Date(point.timestamp).toLocaleTimeString(),
    active: point.queueMetrics?.active || 0,
    waiting: point.queueMetrics?.waiting || 0,
    completed: point.queueMetrics?.completed || 0,
    failed: point.queueMetrics?.failed || 0,
    processingTime: point.performance?.processingTime || 0
  })).reverse().slice(0, 20);

  const queueMetrics = metrics.current.queueMetrics || {};
  const sessionCounts = metrics.current.queueMetrics || {};
  const performance = metrics.current.performance || {};

  // Calculate rates
  const totalProcessed = queueMetrics.completed + queueMetrics.failed || 0;
  const errorRate = totalProcessed > 0 
    ? ((queueMetrics.failed / totalProcessed) * 100).toFixed(1) 
    : 0;
  const successRate = ((performance.completionRate || 0) * 100).toFixed(1);

  // Calculate trend indicators (comparing with previous period)
  const isErrorIncreasing = chartData.length >= 2 && 
    chartData[0].failed > chartData[1].failed;
  const isSuccessIncreasing = chartData.length >= 2 && 
    chartData[0].completed > chartData[1].completed;

  return (
    <div className="space-y-6">
      <Card className="w-full">
        <CardHeader className="flex flex-col sm:flex-row items-center justify-between pb-2">
          <CardTitle className="text-xl font-bold">{stage}</CardTitle>
          {metrics.lastError && (
            <div className="flex items-center px-2 py-1 text-sm text-red-600 bg-red-100 rounded-full mt-2 sm:mt-0">
              <AlertCircle className="w-4 h-4 mr-1" />
              Error Detected
            </div>
          )}
        </CardHeader>
        <CardContent className="grid gap-4">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {/* Queue Status Section */}
            <div className="space-y-1">
              <p className="text-sm font-semibold ml-2">Queue Status</p>
              <MetricRow 
                icon={Activity} 
                label="Active Jobs" 
                value={queueMetrics.active || 0}
                colorClass="text-blue-500"
              />
              <MetricRow 
                icon={Clock} 
                label="Waiting" 
                value={queueMetrics.waiting || 0}
                colorClass="text-orange-500"
              />
            </div>

            {/* Performance Section */}
            <div className="space-y-1">
              <p className="text-sm font-semibold ml-2">Performance</p>
              <MetricRow 
                icon={Users} 
                label="Completed" 
                value={queueMetrics.completed || 0}
                subValue={isSuccessIncreasing ? 
                  <ArrowUpRight className="w-4 h-4 text-green-500 inline" /> : 
                  <ArrowDownRight className="w-4 h-4 text-red-500 inline" />
                }
                colorClass="text-green-500"
              />
              <MetricRow 
                icon={AlertCircle} 
                label="Failed" 
                value={queueMetrics.failed || 0}
                subValue={isErrorIncreasing ? 
                  <ArrowUpRight className="w-4 h-4 text-red-500 inline" /> : 
                  <ArrowDownRight className="w-4 h-4 text-green-500 inline" />
                }
                colorClass="text-red-500"
              />
            </div>

            {/* Metrics Section */}
            <div className="space-y-1">
              <p className="text-sm font-semibold ml-2">Metrics</p>
              <MetricRow 
                icon={Timer} 
                label="Processing Time" 
                value={((performance.processingTime || 0) / 1000).toFixed(1)}
                subValue="seconds"
                colorClass="text-purple-500"
              />
              <MetricRow 
                icon={Activity} 
                label="Success Rate" 
                value={`${successRate}%`}
                colorClass="text-emerald-500"
              />
            </div>
          </div>

          {/* Chart Section */}
          {chartData.length > 0 && (
            <div className="h-64 mt-4">
              <ResponsiveContainer width="100%" height="100%">
                <LineChart data={chartData}>
                  <CartesianGrid strokeDasharray="3 3" opacity={0.3} />
                  <XAxis 
                    dataKey="time" 
                    fontSize={12}
                    tickFormatter={(value) => value.split(':').slice(0, 2).join(':')}
                  />
                  <YAxis fontSize={12} />
                  <Tooltip 
                    contentStyle={{ 
                      backgroundColor: 'rgba(255, 255, 255, 0.95)',
                      borderRadius: '6px',
                      padding: '8px',
                      border: '1px solid #e2e8f0'
                    }}
                  />
                  <Legend verticalAlign="top" height={36} />
                  <Line 
                    type="monotone" 
                    dataKey="active" 
                    name="Active" 
                    stroke="#3b82f6" 
                    dot={false}
                    strokeWidth={2}
                  />
                  <Line 
                    type="monotone" 
                    dataKey="completed" 
                    name="Completed" 
                    stroke="#22c55e" 
                    dot={false}
                    strokeWidth={2}
                  />
                  <Line 
                    type="monotone" 
                    dataKey="failed" 
                    name="Failed" 
                    stroke="#ef4444" 
                    dot={false}
                    strokeWidth={2}
                  />
                  <Line 
                    type="monotone" 
                    dataKey="waiting" 
                    name="Waiting" 
                    stroke="#f97316" 
                    dot={false}
                    strokeWidth={2}
                  />
                </LineChart>
              </ResponsiveContainer>
            </div>
          )}

          {/* Error Message */}
          {metrics.lastError && (
            <div className="px-4 py-3 bg-red-50 border border-red-200 rounded-lg">
              <p className="text-sm font-medium text-red-800">
                {metrics.lastError.message}
              </p>
              <p className="text-xs text-red-600 mt-1">
                {new Date(metrics.lastError.timestamp).toLocaleString()}
              </p>
            </div>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default StageMetrics;
