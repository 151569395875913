import * as React from "react"
import { cn } from "utils/cn"

const Avatar = React.forwardRef(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn(
      "relative flex h-10 w-10 shrink-0 overflow-hidden rounded-full",
      className
    )}
    {...props}
  />
))
Avatar.displayName = "Avatar"

const AvatarImage = React.forwardRef(({ className, src, alt = "", onError, ...props }, ref) => {
  const [hasError, setHasError] = React.useState(false);

  const handleError = (e) => {
    setHasError(true);
    if (onError) onError(e);
  };

  if (hasError) return null;

  return (
    <img
      ref={ref}
      src={src}
      alt={alt}
      onError={handleError}
      className={cn("aspect-square h-full w-full object-cover", className)}
      {...props}
    />
  );
})
AvatarImage.displayName = "AvatarImage"

const AvatarFallback = React.forwardRef(({ className, delayMs = 600, ...props }, ref) => {
  const [isVisible, setIsVisible] = React.useState(false);

  React.useEffect(() => {
    const timeout = setTimeout(() => setIsVisible(true), delayMs);
    return () => clearTimeout(timeout);
  }, [delayMs]);

  if (!isVisible) return null;

  return (
    <div
      ref={ref}
      className={cn(
        "flex h-full w-full items-center justify-center rounded-full bg-muted text-sm font-medium",
        className
      )}
      {...props}
    />
  );
})
AvatarFallback.displayName = "AvatarFallback"

export { Avatar, AvatarImage, AvatarFallback }