import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../services/api';

export const fetchQuestions = createAsyncThunk(
  'questionnaire/fetchQuestions',
  async (language) => {
    const response = await api.get(`/api/questionnaire/${language}`);
    return response.data;
  }
);

export const submitAnswers = createAsyncThunk(
  'questionnaire/submitAnswers',
  async (answers) => {
    const response = await api.post('/api/questionnaire/submit', answers);
    return response.data;
  }
);

const questionnaireSlice = createSlice({
  name: 'questionnaire',
  initialState: {
    questions: [],
    currentQuestion: 0,
    answers: {},
    results: null,
    status: 'idle',
    error: null,
  },
  reducers: {
    answerQuestion: (state, action) => {
      state.answers[action.payload.questionId] = action.payload.answer;
      state.currentQuestion += 1;
    },
    resetQuestionnaire: (state) => {
      state.currentQuestion = 0;
      state.answers = {};
      state.results = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchQuestions.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchQuestions.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.questions = action.payload;
      })
      .addCase(fetchQuestions.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(submitAnswers.fulfilled, (state, action) => {
        state.results = action.payload;
      });
  },
});

export const { answerQuestion, resetQuestionnaire } = questionnaireSlice.actions;

export default questionnaireSlice.reducer;