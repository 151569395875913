import React from "react";


export const Progress = React.forwardRef(({ value, className, ...props }, ref) => (
    <div
      className={`relative h-2 w-full overflow-hidden rounded-full bg-gray-200 dark:bg-gray-800 ${className}`}
    >
      <div
        ref={ref}
        className="h-full w-full flex-1 bg-blue-600 transition-all dark:bg-blue-400"
        style={{ width: `${value}%` }}
        {...props}
      />
    </div>
  ));
  
  Progress.displayName = "Progress";