import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import request from 'utils/AxiosUtils';
import { changePassword, profile } from 'utils/AxiosUtils/API';
import SimpleInputField from 'components/InputFields/SimpleInputField';
import Btn from 'Elements/Buttons/Btn';
import { Card } from 'components/ui/card';
import { Tabs, TabsContent, TabsList, TabsTrigger } from 'components/ui/tabs';
import { Alert, AlertDescription } from 'components/ui/alert';
import { Avatar, AvatarFallback, AvatarImage } from 'components/ui/avatar';
import { User, Mail, Lock, Edit2, Save, X } from 'lucide-react';
import useUpdate from 'utils/Hooks/useUpdate';

export default function Profile() {
  const [editMode, setEditMode] = useState(false);
  const [profileData, setProfileData] = useState({});
  const [saveStatus, setSaveStatus] = useState(null);
  const { mutate:profilteMutate, profileLoading } = useUpdate(profile, null,null,"",null,"patch");
  const { mutate, Loading } = useUpdate(changePassword, null,null,"",null,"patch");

  const { data: userProfileData, isLoading, refetch } = useQuery({
    queryKey: [profile],
    queryFn: () => request({ url: profile }),
    refetchOnMount: false,
    enabled: false,
  });

  const initialProfileValues = {
    name: profileData.name || '',
    email: profileData.email || '',
  };

  const profileValidationSchema = Yup.object({
    name: Yup.string().required('Name is required'),
    email: Yup.string().email('Invalid email address').required('Email is required'),
  });

  const passwordValidationSchema = Yup.object({
    currentPassword: Yup.string().required('Current password is required'),
    newPassword: Yup.string()
      .min(8, 'Password must be at least 8 characters')
   //   .matches(/[A-Z]/, 'Must contain at least one uppercase letter')
      .matches(/[a-z]/, 'Must contain at least one lowercase letter')
      .matches(/[0-9]/, 'Must contain at least one number')
      .required('New password is required'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
      .required('Confirm password is required'),
  });

  const handleSave = async (values) => {
    try {
      profilteMutate(values);
      setProfileData(values);
      setEditMode(false);
      setSaveStatus({ type: 'success', message: 'Profile updated successfully!' });
    } catch (error) {
      setSaveStatus({ type: 'error', message: 'Failed to update profile. Please try again.' });
    }
  };

  const handlePasswordUpdate = async (values, { resetForm }) => {
    try {
      mutate(values);
      setSaveStatus({ type: 'success', message: 'Password updated successfully!' });
      resetForm();
    } catch (error) {
      setSaveStatus({ type: 'error', message: 'Failed to update password. Please try again.' });
    }
  };

  useEffect(() => {
    if (userProfileData?.data) {
      setProfileData(userProfileData.data.user);
    }
  }, [userProfileData]);

  useEffect(() => {
    refetch();
  }, []);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-[400px]">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary"></div>
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto p-4 space-y-6">
      {saveStatus && (
        <Alert variant={saveStatus.type === 'success' ? 'success' : 'destructive'}>
          <AlertDescription>{saveStatus.message}</AlertDescription>
        </Alert>
      )}

      <Card className="p-6">
        <div className="flex items-center space-x-6 mb-8">
          <Avatar className="h-24 w-24">
            <AvatarImage src={profileData.avatar} />
            <AvatarFallback className="bg-primary/10 text-primary text-xl">
              {profileData.name?.charAt(0) || 'U'}
            </AvatarFallback>
          </Avatar>
          <div>
            <h1 className="text-2xl font-bold">{profileData.name}</h1>
            <p className="text-muted-foreground">{profileData.email}</p>
          </div>
        </div>

        <Tabs defaultValue="profile" className="space-y-6">
          <TabsList className="grid w-full grid-cols-2">
            <TabsTrigger value="profile" className="flex items-center gap-2">
              <User className="h-4 w-4" />
              Profile Information
            </TabsTrigger>
            <TabsTrigger value="security" className="flex items-center gap-2">
              <Lock className="h-4 w-4" />
              Security
            </TabsTrigger>
          </TabsList>

          <TabsContent value="profile" className="space-y-6">
            {editMode ? (
              <Formik
                initialValues={initialProfileValues}
                validationSchema={profileValidationSchema}
                onSubmit={handleSave}
                enableReinitialize
              >
                {({ isSubmitting }) => (
                  <Form className="space-y-4">
                    <SimpleInputField
                      nameList={[
                        {
                          name: 'name',
                          title: 'Name',
                          require: "true",
                          placeholder: "Enter your name",
                          icon: <User className="h-4 w-4" />
                        },
                        {
                          name: 'email',
                          title: 'Email',
                          require: "true",
                          placeholder: "Enter your email",
                          icon: <Mail className="h-4 w-4" />
                        }
                      ]}
                    />
                    <div className="flex gap-2">
                      <Btn
                        type="submit"
                        variant="default"
                        disabled={isSubmitting}
                        className="flex items-center gap-2"
                      >
                        <Save className="h-4 w-4" />
                        Save Changes
                      </Btn>
                      <Btn
                        type="button"
                        variant="outline"
                        onClick={() => setEditMode(false)}
                        className="flex items-center gap-2"
                      >
                        <X className="h-4 w-4" />
                        Cancel
                      </Btn>
                    </div>
                  </Form>
                )}
              </Formik>
            ) : (
              <div className="space-y-4">
                <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                  <div className="space-y-2">
                    <p className="text-sm font-medium text-muted-foreground">Name</p>
                    <p className="text-lg">{profileData.name}</p>
                  </div>
                  <div className="space-y-2">
                    <p className="text-sm font-medium text-muted-foreground">Email</p>
                    <p className="text-lg">{profileData.email}</p>
                  </div>
                </div>
                <Btn
                  onClick={() => setEditMode(true)}
                  variant="outline"
                  className="flex items-center gap-2"
                >
                  <Edit2 className="h-4 w-4" />
                  Edit Profile
                </Btn>
              </div>
            )}
          </TabsContent>

          <TabsContent value="security" className="space-y-6">
            <div className="space-y-4">
              <h3 className="text-lg font-medium">Change Password</h3>
              <Formik
                initialValues={{ currentPassword: '', newPassword: '', confirmPassword: '' }}
                validationSchema={passwordValidationSchema}
                onSubmit={handlePasswordUpdate}
              >
                {({ isSubmitting }) => (
                  <Form className="space-y-4">
                    <SimpleInputField
                      nameList={[
                        {
                          name: 'currentPassword',
                          title: 'Current Password',
                          type: 'password',
                          require: "true",
                          placeholder: "Enter current password",
                          icon: <Lock className="h-4 w-4" />
                        },
                        {
                          name: 'newPassword',
                          title: 'New Password',
                          type: 'password',
                          require: "true",
                          placeholder: "Enter new password",
                          icon: <Lock className="h-4 w-4" />
                        },
                        {
                          name: 'confirmPassword',
                          title: 'Confirm Password',
                          type: 'password',
                          require: "true",
                          placeholder: "Confirm new password",
                          icon: <Lock className="h-4 w-4" />
                        }
                      ]}
                    />
                    <Btn
                      type="submit"
                      variant="default"
                      disabled={isSubmitting}
                      className="flex items-center gap-2"
                    >
                      <Save className="h-4 w-4" />
                      Update Password
                    </Btn>
                  </Form>
                )}
              </Formik>
            </div>
          </TabsContent>
        </Tabs>
      </Card>
    </div>
  );
}