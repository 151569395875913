import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ProcessingStage from "./ProcessingStage";
import { Progress } from 'components/ui/progress';

const RecommendationStage = ({ sessionId, onComplete,language }) => {
    const { t } = useTranslation();
    const [data, setData] = useState(null);
    const [selectedCareer, setSelectedCareer] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchRecommendations = useCallback(async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/flow/process-recommendation`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ sessionId,
                    language  })
            });
            if (!response.ok) {
                throw new Error(t('failedToFetchRecommendations'));
            }
            const result = await response.json();
            setData(result.recommendations);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching recommendations:', error);
            setError(error.message);
            setLoading(false);
        }
    }, [sessionId, t]);

    useEffect(() => {
        fetchRecommendations();
    }, [fetchRecommendations]);

    const handleContinue = async () => {
        try {
            onComplete?.();
        } catch (error) {
            console.error('Error proceeding to next stage:', error);
            setError(error.message);
        }
    };

    if (loading || !data) {
        return <ProcessingStage message={t('generatingCareerRecommendations')} />;
    }

    const getMatchColor = (score) => {
        if (score >= 85) return 'bg-green-600';
        if (score >= 70) return 'bg-blue-600';
        return 'bg-yellow-600';
    };

    if (error) {
        return (
            <div className="text-center py-8">
                <h3 className="text-red-800 font-medium mb-2">{t('errorLoadingRecommendations')}</h3>
                <p className="text-red-600">{error}</p>
                <button
                    onClick={fetchRecommendations}
                    className="px-4 py-2 bg-red-100 text-red-700 rounded-md hover:bg-red-200 transition-colors"
                >
                    {t('tryAgain')}
                </button>
            </div>
        );
    }

    const renderCareerCard = (career, index) => (
        <div
            key={index}
            className={`mb-6 p-6 border rounded-lg transition-all duration-300 ${
                selectedCareer === index ? 'border-blue-500 shadow-lg' : 'hover:shadow-md'
            }`}
            onClick={() => setSelectedCareer(index)}
        >
            <div className="flex justify-between items-start">
                <div className="flex-1">
                    <h3 className="text-xl font-semibold mb-2">{t(career.title)}</h3>
                    <p className="text-gray-600 mb-4">{t(career.description)}</p>

                    {/* Match Score */}
                    <div className="mb-4">
                        <div className="flex justify-between items-center mb-2">
                            <span className="text-sm font-medium">{t('matchScore')}</span>
                            <span className="text-sm font-bold">{career.matchScore}%</span>
                        </div>
                        <Progress
                            value={career.matchScore}
                            className="h-2"
                            indicatorClassName={getMatchColor(career.matchScore)}
                        />
                    </div>

                    {/* Key Skills */}
                    {career.requiredSkills && (
                        <div className="mb-4">
                            <h4 className="font-medium mb-2">{t('keySkills')}</h4>
                            <div className="flex flex-wrap gap-2">
                                {career.requiredSkills.map((skill, idx) => (
                                    <span
                                        key={idx}
                                        className="px-3 py-1 bg-blue-100 text-blue-800 rounded-full text-sm"
                                    >
                                        {t(skill)}
                                    </span>
                                ))}
                            </div>
                        </div>
                    )}

                    {/* Development Areas */}
                    {career.developmentAreas && (
                        <div className="mt-4">
                            <h4 className="font-medium mb-2">{t('areasForDevelopment')}</h4>
                            <div className="space-y-2">
                                {career.developmentAreas.map((area, idx) => (
                                    <div key={idx} className="flex justify-between items-center">
                                        <span className="text-sm">{t(area.skill)}</span>
                                        <div className="flex items-center gap-2">
                                            <Progress
                                                value={(area.currentLevel / area.targetLevel) * 100}
                                                className="w-24 h-2"
                                            />
                                            <span className="text-xs text-gray-500">
                                                {t(area.priority)}
                                            </span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            </div>

            {/* Expanded Details */}
            {selectedCareer === index && (
                <div className="mt-6 pt-6 border-t">
                    {/* Market Information */}
                    <div className="grid grid-cols-3 gap-4 mb-6">
                        <div className="text-center">
                            <div className="text-2xl font-bold text-blue-600">
                                {career.marketDemand}%
                            </div>
                            <div className="text-sm text-gray-600">{t('marketDemand')}</div>
                        </div>
                        <div className="text-center">
                            <div className="text-2xl font-bold text-green-600">
                                {career.growthPotential}%
                            </div>
                            <div className="text-sm text-gray-600">{t('growthPotential')}</div>
                        </div>
                        <div className="text-center">
                            <div className="text-2xl font-bold text-purple-600">
                                {career.skillAlignment?.length || 0}
                            </div>
                            <div className="text-sm text-gray-600">{t('matchingSkills')}</div>
                        </div>
                    </div>

                    {/* Learning Path */}
                    {data.learningPaths?.[index] && (
                        <div className="mb-6">
                            <h4 className="font-semibold mb-3">{t('recommendedLearningPath')}</h4>
                            <div className="space-y-3">
                                {data.learningPaths[index].path.map((step, idx) => (
                                    <div
                                        key={idx}
                                        className="flex items-start gap-3 p-3 bg-gray-50 rounded"
                                    >
                                        <div className="w-6 h-6 rounded-full bg-blue-100 text-blue-800 flex items-center justify-center flex-shrink-0">
                                            {idx + 1}
                                        </div>
                                        <div>
                                            <div className="font-medium">{t(step.title)}</div>
                                            <div className="text-sm text-gray-600">
                                                {t('duration')}: {t(step.duration)}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}

                    {/* Industry Insights */}
                    {data.industryInsights?.insights?.[index] && (
                        <div>
                            <h4 className="font-semibold mb-3">{t('industryInsights')}</h4>
                            <div className="grid grid-cols-2 gap-4">
                                <div className="p-3 bg-gray-50 rounded">
                                    <div className="text-sm text-gray-600">{t('growthRate')}</div>
                                    <div className="font-medium">
                                        {data.industryInsights.insights[index].growthRate}
                                    </div>
                                </div>
                                <div className="p-3 bg-gray-50 rounded">
                                    <div className="text-sm text-gray-600">{t('marketDemand')}</div>
                                    <div className="font-medium">
                                        {data.industryInsights.insights[index].marketDemand}%
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );

    return (
        <div className="max-w-4xl mx-auto space-y-6">
            <div className="bg-white rounded-lg p-6 shadow-sm">
                <h2 className="text-2xl font-bold mb-6">{t('yourCareerMatches')}</h2>
                <div className="space-y-4">
                    {data.careers.map(renderCareerCard)}
                </div>
            </div>

            {/* Summary Section */}
            <div className="bg-blue-50 p-6 rounded-lg">
                <h3 className="font-semibold mb-3">{t('matchSummary')}</h3>
                <p className="text-sm text-blue-800">
                    {t('matchSummaryText', {
                        count: data.careers.length,
                        topMatch: data.careers[0]?.matchScore
                    })}
                </p>
            </div>
            <div className="flex justify-end mt-6">
                <button
                    onClick={handleContinue}
                    className="px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
                >
                    {t('continueToReport')}
                </button>
            </div>
        </div>
    );
};

export default RecommendationStage;