import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { 
  Award, Book, Target, TrendingUp, Users, 
  Download, Share2, RefreshCw, ExternalLink, 
  ChevronRight, BarChart2, Calendar, MessageSquare,
  ThumbsUp, ThumbsDown, Minus
} from 'lucide-react';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from 'components/ui/card';
import { Progress } from 'components/ui/progress';
import PrintableReport from './PrintableReport';

const FeedbackButton = ({ icon: Icon, label, onClick, selected }) => {
  const { t } = useTranslation();
  return (
    <button
      onClick={onClick}
      className={`flex items-center px-4 py-2 rounded-lg transition-all duration-300 ${
        selected 
          ? 'bg-blue-100 text-blue-700 border-blue-200' 
          : 'bg-white border-gray-200 hover:bg-gray-50'
      } border`}
    >
      <Icon className="w-4 h-4 mr-2" />
      {t(label)}
    </button>
  );
};

const CompletedStage = ({ onRestart }) => {
  const { t } = useTranslation();
  const [animationProgress, setAnimationProgress] = useState(0);
  const [showConfetti, setShowConfetti] = useState(true);
  const [careerFeedback, setCareerFeedback] = useState({});
  const [overallFeedback, setOverallFeedback] = useState(null);
  const [isPrinting, setIsPrinting] = useState(false);
  const [report, setReport] = useState(null);
  const printRef = useRef();

  const handlePrint = async () => {
    setIsPrinting(true);
    requestAnimationFrame(() => {
      window.print();
      setIsPrinting(false);
    });
  };

  useEffect(() => {
    const timer = setTimeout(() => setAnimationProgress(100), 500);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => setShowConfetti(false), 3000);
    return () => clearTimeout(timer);
  }, []);

  const handleShare = async () => {
    try {
      if (navigator.share) {
        await navigator.share({
          title: t('careerAssessmentResults'),
          text: t('topCareerMatches', {
            careers: report?.details?.recommendations?.careers.map(c => t(c.title)).join(', ')
          }),
          url: window.location.href
        });
      } else {
        await navigator.clipboard.writeText(window.location.href);
        alert(t('linkCopiedToClipboard'));
      }
    } catch (error) {
      console.error('Error sharing:', error);
    }
  };

  return (
    <div className="max-w-4xl mx-auto space-y-6">
      <Card className="relative overflow-hidden bg-gradient-to-br from-blue-50 to-white border-blue-100">
        {showConfetti && (
          <div className="absolute inset-0 pointer-events-none">
            <div className="absolute w-3 h-3 bg-blue-400 rotate-45 animate-confetti-1" />
            <div className="absolute w-3 h-3 bg-green-400 rotate-45 animate-confetti-2" />
            <div className="absolute w-3 h-3 bg-yellow-400 rotate-45 animate-confetti-3" />
          </div>
        )}
        
        <CardHeader className="text-center">
          <div className="mx-auto mb-4 h-16 w-16 rounded-full bg-blue-100 flex items-center justify-center transform transition-transform hover:scale-110">
            <Award className="h-8 w-8 text-blue-600" />
          </div>
          <CardTitle className="text-3xl font-bold text-blue-900 mb-2">
            {t('careerJourneyBegins')}
          </CardTitle>
          <CardDescription className="text-lg text-blue-600">
            {t('personalityDescription', {
              traits: t('communicativeAndAnalytical'),
              preference: t('structuredWithCreative')
            })}
          </CardDescription>
        </CardHeader>

        <CardContent className="space-y-8">
          <div>
            <h3 className="text-xl font-semibold mb-4">{t('yourTopSkills')}</h3>
            <div className="space-y-4">
              {report?.summary?.topSkills?.map((skill, index) => (
                <div key={index} className="space-y-2">
                  <div className="flex justify-between">
                    <span className="font-medium">{t(skill.name)}</span>
                    <span className="text-blue-600">{skill.score}%</span>
                  </div>
                  <Progress value={skill.score} className="h-2" />
                  <p className="text-sm text-gray-600">{t(skill.developmentTips)}</p>
                </div>
              ))}
            </div>
          </div>

          <div>
            <h3 className="text-xl font-semibold mb-4">{t('yourValuesAndInterests')}</h3>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              {[t('continuousLearning'), t('workLifeBalance'), t('innovation')].map((value, index) => (
                <div key={index} className="p-4 bg-white rounded-lg border border-blue-100">
                  <div className="font-medium text-blue-900">{value}</div>
                </div>
              ))}
            </div>
          </div>
        </CardContent>
      </Card>

      <Card>
        <CardHeader>
          <CardTitle className="text-2xl">{t('recommendedCareerPaths')}</CardTitle>
          <CardDescription>{t('careerAlignmentDescription')}</CardDescription>
        </CardHeader>
        <CardContent>
          <div className="space-y-6">
            {report?.details?.recommendations?.careers?.map((career, index) => (
              <div key={index} className="p-6 bg-white rounded-lg border border-gray-200 hover:border-blue-200 transition-all duration-300">
                <div className="flex justify-between items-start mb-4">
                  <div>
                    <h4 className="text-xl font-semibold text-blue-900">{t(career.title)}</h4>
                    <p className="text-gray-600 mt-1">{t(career.description)}</p>
                  </div>
                  <div className="text-right">
                    <div className="text-2xl font-bold text-blue-600">{career.matchScore}%</div>
                    <div className="text-sm text-gray-500">{t('matchScore')}</div>
                  </div>
                </div>

                <div className="space-y-4">
                  <div className="mt-4">
                    <h5 className="font-medium mb-2">{t('careerGrowthPath')}</h5>
                    <div className="grid grid-cols-3 gap-4">
                      {Object.entries(career.progression || {}).map(([level, info]) => (
                        <div key={level} className="p-3 bg-gray-50 rounded">
                          <div className="font-medium">{t(info.role)}</div>
                          <div className="text-sm text-gray-600">{t(info.timeframe)}</div>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="flex gap-2 mt-4">
                    <FeedbackButton
                      icon={ThumbsUp}
                      label="agree"
                      selected={careerFeedback[career.title] === 'agree'}
                      onClick={() => setCareerFeedback(prev => ({
                        ...prev,
                        [career.title]: 'agree'
                      }))}
                    />
                    <FeedbackButton
                      icon={Minus}
                      label="neutral"
                      selected={careerFeedback[career.title] === 'neutral'}
                      onClick={() => setCareerFeedback(prev => ({
                        ...prev,
                        [career.title]: 'neutral'
                      }))}
                    />
                    <FeedbackButton
                      icon={ThumbsDown}
                      label="disagree"
                      selected={careerFeedback[career.title] === 'disagree'}
                      onClick={() => setCareerFeedback(prev => ({
                        ...prev,
                        [career.title]: 'disagree'
                      }))}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </CardContent>
      </Card>

      <Card>
        <CardHeader>
          <CardTitle className="text-2xl">{t('yourLearningPath')}</CardTitle>
          <CardDescription>{t('personalizedRoadmap')}</CardDescription>
        </CardHeader>
        <CardContent>
          <div className="space-y-4">
            {[
              { title: 'introDataAnalysis', duration: '4weeks' },
              { title: 'sqlDataAnalysis', duration: '6weeks' },
              { title: 'dataVisualization', duration: '4weeks' },
              { title: 'mlFundamentals', duration: '8weeks' }
            ].map((course, index) => (
              <div key={index} className="flex items-center gap-4 p-4 bg-gray-50 rounded-lg">
                <div className="flex-shrink-0 w-8 h-8 bg-blue-100 rounded-full flex items-center justify-center">
                  {index + 1}
                </div>
                <div className="flex-1">
                  <h4 className="font-medium">{t(course.title)}</h4>
                  <p className="text-sm text-gray-600">{t(course.duration)}</p>
                </div>
                <button className="text-blue-600 hover:text-blue-700">
                  <ExternalLink className="w-5 h-5" />
                </button>
              </div>
            ))}
          </div>
        </CardContent>
      </Card>

      <div className="flex flex-col sm:flex-row gap-4 justify-center">
        <button
          onClick={handlePrint}
          className="flex items-center justify-center px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-all duration-300 hover:shadow-lg"
        >
          <Download className="w-5 h-5 mr-2" />
          {t('downloadReport')}
        </button>
        <button
          onClick={handleShare}
          className="flex items-center justify-center px-6 py-3 bg-white border border-blue-200 text-blue-600 rounded-lg hover:bg-blue-50 transition-all duration-300 hover:shadow-lg"
        >
          <Share2 className="w-5 h-5 mr-2" />
          {t('shareResults')}
        </button>
        <button
          onClick={onRestart}
          className="flex items-center justify-center px-6 py-3 bg-white border border-blue-200 text-blue-600 rounded-lg hover:bg-blue-50 transition-all duration-300 hover:shadow-lg"
        >
          <RefreshCw className="w-5 h-5 mr-2" />
          {t('startNewAssessment')}
        </button>
      </div>

      <Card>
        <CardHeader>
          <CardTitle>{t('yourFeedback')}</CardTitle>
          <CardDescription>{t('helpUsImprove')}</CardDescription>
        </CardHeader>
        <CardContent>
          <div className="flex justify-center gap-4">
            <FeedbackButton
              icon={ThumbsUp}
              label="veryHelpful"
              selected={overallFeedback === 'very-helpful'}
              onClick={() => setOverallFeedback('very-helpful')}
            />
            <FeedbackButton
              icon={Minus}
              label="somewhatHelpful"
              selected={overallFeedback === 'somewhat-helpful'}
              onClick={() => setOverallFeedback('somewhat-helpful')}
            />
            <FeedbackButton
              icon={ThumbsDown}
              label="notHelpful"
              selected={overallFeedback === 'not-helpful'}
              onClick={() => setOverallFeedback('not-helpful')}
            />
          </div>
        </CardContent>
      </Card>

      <div ref={printRef} className={`print-container ${isPrinting ? 'block' : 'hidden'}`}>
        <PrintableReport report={report} />
      </div>
    </div>
  );
};

export default CompletedStage;