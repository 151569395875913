import React, { useState, useEffect, useCallback, useMemo, useContext } from 'react';
import { Model } from 'survey-core';
import { Survey } from 'survey-react-ui';
import { useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga';
import { useFlowState } from 'utils/Hooks/useFlowState';
import { useSession } from 'utils/Hooks/useSession';
import { useMutation , useQueryClient } from '@tanstack/react-query';
import questionnaireData from '../../Data/MultilingualCareerQuestionnaire';
import ScoringStage from './ScoringStage';
import ReportingStage from './ReportingStage';
import 'survey-core/defaultV2.min.css';
import RecommendationStage from './RecommendationStage';
import CompletedStage from './CompletedStage';
import CompletionStage from './CompletionStage';
import { useTranslation, withTranslation, Trans } from 'react-i18next';
const CareerQuestionnaire = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();
  const { 
    sessionId, 
    isLoading: isSessionLoading, 
    checkSession 
  } = useSession();
  const flowState = useFlowState(sessionId);
  const [survey, setSurvey] = useState(null);
  const [completionReport, setCompletionReport] = useState(null);
  const [language, setLanguage] = useState('en');
  const [isLanguageChanging, setIsLanguageChanging] = useState(false);
  const [savedAnswers, setSavedAnswers] = useState({
  });
  const [surveyData, setSurveyData] = useState({
    questionnaire: null,
    scores: null,
    recommendations: null,
    report: null
  });

  const handleRecommendationComplete = () => {
    submitAnswerMutation.mutate({
      sessionId,
      stage: 'recommendation',
      completed: true,
      language:language
    });
  };
  const handleReportingComplete = () => {
    submitAnswerMutation.mutate({
      sessionId,
      stage: 'reporting',
      completed: true,
      language:language
    });
  };
  // Submit answer mutation
  const submitAnswerMutation = useMutation({
    mutationFn: async (data) => {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/flow/submit`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          sessionId,
          answer: data,
          stage: flowState.stage,
          language
        })
      });

      if (!response.ok) throw new Error('Failed to submit answer');
      return response.json();
    },
    onSuccess: (response) => {
      console.log('Submission success:', response);
      setSurveyData(prev => ({
        ...prev,
        [flowState.stage]: response.stage
      }));
    
      queryClient.invalidateQueries(['flowState', sessionId]);
    },
    onError: (error) => {
      console.error('Submission error:', error);
    }
  });
  useEffect(() => {
    if (sessionId) {
      checkSession(sessionId).catch(() => {
        // Handle invalid session
        window.location.reload();
      });
    }
  }, [sessionId, checkSession]);
  // Analytics setup
  useEffect(() => {
    ReactGA.initialize("UA-XXXXXXXXX-X", {
      gaOptions: {
        storage: 'none',
        storeGac: false
      }
    });
    ReactGA.set({ anonymizeIp: true });
    ReactGA.pageview(window.location.pathname);
  }, []);

  // Survey configuration
  const surveyConfig = useMemo(() => ({
    pages: [
      {
        name: 'careerQuestionnaire',
        elements: questionnaireData.questions.map((q) => ({
          type: q.type === 'single-choice' ? 'radiogroup' : 'matrix',
          name: q.id,
          title: q[language].question,
          isRequired: true,
          choices: q.type === 'single-choice' ? q[language].options : undefined,
          columns: q.type === 'comparison' ? ['A', 'B'] : undefined,
          rows: q.type === 'comparison' ? q[language].pairs.map((pair) => ({
            value: pair.id,
            text: `${pair.a} vs ${pair.b}`
          })) : undefined
        }))
      }
    ],
    showQuestionNumbers: false,
    showProgressBar: 'bottom',
    checkErrorsMode: 'onComplete',
    locale: language,
    completeText: t("Complete"),
    pagePrevText: t("Previous"),
    pageNextText:t("Next"),
  }), [language]);

  // Survey initialization
  const handleValueChanged = useCallback((sender, options) => {
    console.log("sender.data",sender.data);
    // setSavedAnswers(sender.data);
  }, []);

  // Survey initialization with proper answer restoration
  useEffect(() => {
    const newSurvey = new Model(surveyConfig);
    
    // Add value changed event handler
    newSurvey.onValueChanged.add(handleValueChanged);
    
    // Add complete handler
    newSurvey.onComplete.add(handleSurveyComplete);
    console.log("inside",savedAnswers);
    // Restore saved answers immediately after creating new survey
    if (Object.keys(savedAnswers).length > 0) {
      newSurvey.data = savedAnswers;
      newSurvey.pages.forEach(page => {
        page.questions.forEach(question => {
          if (savedAnswers[question.name] !== undefined) {
            question.value = savedAnswers[question.name];
          }
        });
      });
      newSurvey.render();
    }
    
    setSurvey(newSurvey);

    return () => {
      newSurvey.onValueChanged.remove(handleValueChanged);
      newSurvey.onComplete.remove(handleSurveyComplete);
      newSurvey.dispose();
    };
  }, [surveyConfig, savedAnswers]);
  useEffect(() => {
    if (survey && !isLanguageChanging && Object.keys(savedAnswers).length > 0) {
      Object.entries(savedAnswers).forEach(([key, value]) => {
        if (survey.data[key] !== value) {
          survey.setValue(key, value);
        }
      });
    }
  }, [survey, savedAnswers, isLanguageChanging]);
  // Event handlers
  const handleSurveyComplete = useCallback(async (sender) => {
    try {
      await submitAnswerMutation.mutateAsync(sender.data);
      trackGAEvent("Completed", "User completed the questionnaire");
    } catch (error) {
      console.error('Error submitting survey:', error);
    }
  }, [submitAnswerMutation]);

  const trackGAEvent = useCallback((action, label) => {
    try {
      ReactGA.event({
        category: "Questionnaire",
        action,
        label,
        transport: 'beacon'
      });
    } catch (error) {
      console.error('Error tracking event:', error);
    }
  }, []);
  useEffect(() => {
    
  
    return () => {
      console.log("there is flowState",flowState)
    }
  }, [flowState])
  useEffect(() => {
    if (survey) {
      survey.completeText = t("Complete");
      survey.pagePrevText = t("Previous");
      survey.pageNextText = t("Next");
      survey.render();
    }
  }, [survey, t]);
  const handleLanguageChange = async (newLanguage) => {
  if (survey) {
    i18n.changeLanguage(newLanguage);
    setIsLanguageChanging(true);

    // Save current answers and prepare to translate them
    const translatedAnswers = { ...survey.data };

    // Iterate through each answer and translate using index matching
    Object.keys(translatedAnswers).forEach((key) => {
      const question = questionnaireData.questions.find((q) => q.id === key);
      console.log("question", question);

      if (question && question.type === 'single-choice') {
        const currentAnswer = translatedAnswers[key];
        console.log("currentAnswer", currentAnswer);
        let oldlanguage = survey.locale;
        if(oldlanguage != "et" && oldlanguage != "ru"){
          oldlanguage = "en";
        }
        console.log("oldlanguage",oldlanguage);
        // Find the index of the current answer in the old language options
        const oldLanguageOptions = question[oldlanguage]?.options;
        console.log("oldLanguageOptions",oldLanguageOptions);
        const answerIndex = oldLanguageOptions?.indexOf(currentAnswer);
        console.log("answerIndex",answerIndex);
        // If the index is valid, find the corresponding option in the new language
        if (answerIndex !== -1) {
          const newLanguageOptions = question[newLanguage].options;
          const translatedOption = newLanguageOptions[answerIndex];
          console.log("translatedOption", translatedOption);

          // Update the answer with the translated option
          translatedAnswers[key] = translatedOption;
        }
      }
    });

    setSavedAnswers(translatedAnswers);
console.log(survey.locale);
    // Change survey language
    survey.locale = newLanguage;

    // Wait for the language change to take effect
    await new Promise((resolve) => setTimeout(resolve, 0));

    // Apply the language change to the questions and options
    questionnaireData.questions.forEach((question) => {
      const translatedQuestion = question[newLanguage];
      const surveyQuestion = survey.getQuestionByName(question.id);

      if (surveyQuestion) {
        surveyQuestion.title = translatedQuestion.question;
        if (question.type === 'single-choice') {
          surveyQuestion.choices = translatedQuestion.options;
        } else if (question.type === 'comparison') {
          surveyQuestion.rows = translatedQuestion.pairs.map((pair) => ({
            value: pair.id,
            text: `${pair.a} vs ${pair.b}`,
          }));
        }
      }
    });

    // Restore translated answers
    survey.data = translatedAnswers;
    setLanguage(newLanguage);

    setIsLanguageChanging(false);
  } else {
    setLanguage(newLanguage);
  }
};

  
  
  
  
  
  // Stage rendering
  const renderStage = () => {
    switch (flowState.stage) {
      case 'questionnaire':
        return (
          <>
            <div className="language-selector mb-6">
              <label
                htmlFor="language-select"
                className="block text-sm font-medium text-gray-700 mb-2"
              >
                {t('SelectLanguage')}:
              </label>
              <select
                id="language-select"
                value={language}
                onChange={(e) => handleLanguageChange(e.target.value)}
                className="block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              >
                <option value="en">English</option>
                <option value="et">Estonian</option>
                <option value="ru">Russian</option>
              </select>
            </div>
            {survey && (
              <div className="bg-white rounded-lg shadow-sm">
                <Survey model={survey} />
              </div>
            )}
          </>
        );

      case 'scoring':
        return (
          <ScoringStage
            sessionId={sessionId}
            language={language}
            onComplete={() => {
              // Proceed to next stage
              submitAnswerMutation.mutate({
                sessionId,
                stage: 'scoring',
                completed: true
              });
            }}
          />
        );

      case 'recommendation':
        return (
          <RecommendationStage
            sessionId={sessionId}
            language={language}
            onComplete={handleRecommendationComplete}
          />
        );

      case 'reporting':
        return (
          <ReportingStage
            sessionId={sessionId}
            report={surveyData.report}
            language={language}
            onComplete={handleReportingComplete}
            onRestart={() => {
              setSurveyData({
                results: null,
                scores: null,
                recommendations: null,
                report: null
              });
              survey?.clear();
              trackGAEvent("Restarted", "User restarted the questionnaire");
            }}
            setCompletionReport={setCompletionReport}
          />
        );
        case 'reportingComplete':
          return (
            <CompletedStage
            language={language}
            onRestart={() => {
              setSurveyData({
                questionnaire: null,
                scores: null,
                recommendations: null,
                report: null
              });
              survey?.clear();
              trackGAEvent("Restarted", "User started a new assessment");
            }}
            reportData={surveyData.report}
          />
          );


      default:
        return <CompletionStage reportData={surveyData.report} report={completionReport}/>;
    }
  };

  // Loading state
  if (isSessionLoading) {
    return (
      <div className="flex justify-center items-center min-h-[400px]">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary"></div>
      </div>
    );
  }

  // Error state
  if (flowState.status === "error") {
    return (
      <div className="max-w-4xl mx-auto p-6">
        <div className="bg-red-50 border border-red-200 rounded p-4 text-red-700">
          <p>An error occurred. Please try again.</p>
          <button
            onClick={() => window.location.reload()}
            className="mt-2 px-4 py-2 bg-red-100 rounded hover:bg-red-200"
          >
            Retry
          </button>
        </div>
      </div>
    );
  }

  // Main render
  return (
    <div className="questionnaire-container max-w-4xl mx-auto p-6">
      <div className="mb-6">
        <div className="flex justify-between items-center mb-2">
          <span className="text-sm text-muted-foreground">
            Stage: {t(flowState.stage)}
          </span>
          <span className="text-sm font-medium">{flowState.progress}%</span>
        </div>
        <div className="h-2 bg-gray-200 rounded-full overflow-hidden">
          <div
            className="h-full bg-blue-600 transition-all duration-500"
            style={{ width: `${flowState.progress}%` }}
          />
        </div>
      </div>

      <h1 className="text-3xl font-bold text-center mb-8">
        {t('CareerPathQuestionnaire')}
      </h1>

      {renderStage()}
    </div>
  );
};

export default CareerQuestionnaire;