import React, { useEffect, useState, useCallback } from 'react';
import { Progress } from 'components/ui/progress';
import { Loader2, CheckCircle2, XCircle, PlayCircle, PauseCircle, SkipForward, ChevronRight, BarChart } from 'lucide-react';
import { useTranslation } from 'react-i18next';

const getScoringSteps = (t) => [
  {
    id: 'analyze',
    title: t('analyzingResponses'),
    description: t('processingQuestionnaire'),
    duration: 2000,
    progress: 25,
    icon: '🔍'
  },
  {
    id: 'calculate',
    title: t('calculatingScores'),
    description: t('evaluatingSkills'),
    duration: 2000,
    progress: 50,
    icon: '📊'
  },
  {
    id: 'match',
    title: t('matchingProfiles'),
    description: t('findingCareerMatches'),
    duration: 2000,
    progress: 75,
    icon: '🎯'
  },
  {
    id: 'finalize',
    title: t('finalizingAnalysis'),
    description: t('preparingResults'),
    duration: 2000,
    progress: 100,
    icon: '✨'
  }
];

const ScoringStage = ({ sessionId, onComplete,language }) => {
  const { t } = useTranslation();
  const SCORING_STEPS = getScoringSteps(t);
  
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState(null);
  const [scores, setScores] = useState(null);
  const [autoProgress, setAutoProgress] = useState(true);
  const [isProcessingComplete, setIsProcessingComplete] = useState(false);

  const processScoring = useCallback(async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/flow/process-scoring`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ sessionId,language })
      });

      if (!response.ok) {
        throw new Error(t('failedToProcessScoring'));
      }

      const result = await response.json();
      setScores(result.scores);
      setIsProcessingComplete(true);
      return result;
    } catch (error) {
      console.error('Scoring error:', error);
      setError(error.message);
      throw error;
    }
  }, [sessionId, t]);

  const handleNextStep = useCallback(() => {
    if (currentStepIndex < SCORING_STEPS.length - 1) {
      setCurrentStepIndex(prev => prev + 1);
      setProgress(SCORING_STEPS[currentStepIndex + 1].progress);
    } else if (isProcessingComplete) {
      onComplete?.();
    }
  }, [currentStepIndex, isProcessingComplete, onComplete, SCORING_STEPS]);

  const handleSkipAnimation = async () => {
    setAutoProgress(false);
    setCurrentStepIndex(SCORING_STEPS.length - 1);
    setProgress(100);
    try {
      await processScoring();
    } catch (error) {
      throw new Error(t('failedToHandleAnimation'));
    }
  };

  useEffect(() => {
    let timer;
    const currentStep = SCORING_STEPS[currentStepIndex];

    if (currentStep && autoProgress) {
      setProgress(currentStep.progress);

      if (currentStepIndex === SCORING_STEPS.length - 1) {
        processScoring()
          .then(() => {
            timer = setTimeout(() => {
              setIsProcessingComplete(true);
            }, currentStep.duration);
          })
          .catch((error) => {
            setError(error.message);
          });
      } else {
        timer = setTimeout(() => {
          setCurrentStepIndex(prev => prev + 1);
        }, currentStep.duration);
      }
    }

    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [currentStepIndex]);

  if (error) {
    return (
      <div className="text-center py-8">
        <div className="bg-red-50 border border-red-200 rounded-lg p-4 mb-4">
          <h3 className="text-red-800 font-medium mb-2">{t('errorProcessingScores')}</h3>
          <p className="text-red-600">{error}</p>
        </div>
        <button
          onClick={() => window.location.reload()}
          className="px-4 py-2 bg-red-100 text-red-700 rounded-md hover:bg-red-200 transition-colors"
        >
          {t('tryAgain')}
        </button>
      </div>
    );
  }

  const currentStep = SCORING_STEPS[currentStepIndex];
  const isLastStep = currentStepIndex === SCORING_STEPS.length - 1;

  return (
    <div className="max-w-2xl mx-auto p-6 bg-white rounded-lg shadow-sm">
      {/* Progress Header */}
      <div className="mb-8">
        <h2 className="text-2xl font-bold text-center mb-2">
          {currentStep.title}
        </h2>
        <p className="text-center text-gray-600">
          {currentStep.description}
        </p>
      </div>

      {/* Progress Visualization */}
      <div className="space-y-6 mb-8">
        {/* Overall Progress */}
        <div>
          <div className="flex justify-between mb-2">
            <span className="text-sm font-medium">{t('overallProgress')}</span>
            <span className="text-sm text-gray-500">{progress}%</span>
          </div>
          <Progress value={progress} className="h-2" />
        </div>

        {/* Step Progress Indicators */}
        <div className="flex justify-between relative">
          {SCORING_STEPS.map((step, index) => (
            <div
              key={step.id}
              className={`flex flex-col items-center relative z-10 ${
                index <= currentStepIndex ? 'text-blue-600' : 'text-gray-400'
              }`}
            >
              <div
                className={`
                  w-8 h-8 rounded-full flex items-center justify-center
                  ${index <= currentStepIndex ? 'bg-blue-600 text-white' : 'bg-gray-200'}
                  ${index === currentStepIndex ? 'ring-2 ring-blue-300 ring-offset-2' : ''}
                `}
              >
                {index < currentStepIndex ? (
                  <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
                    <path
                      fillRule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                ) : (
                  index + 1
                )}
              </div>
              <div className="text-xs mt-2 font-medium">{t(step.id)}</div>
            </div>
          ))}
          <div className="absolute top-4 left-0 right-0 h-[2px] bg-gray-200 -z-10">
            <div
              className="h-full bg-blue-600 transition-all duration-500"
              style={{ width: `${progress}%` }}
            />
          </div>
        </div>
      </div>

      {/* Processing Details */}
      <div className="bg-gray-50 rounded-lg p-4">
        <h3 className="font-medium mb-2">{t('processingDetails')}</h3>
        <div className="space-y-2 text-sm text-gray-600">
          <p>• {t('analyzingPatterns')}</p>
          <p>• {t('evaluatingAlignment')}</p>
          <p>• {t('calculatingMatches')}</p>
          <p>• {t('generatingRecommendations')}</p>
        </div>
      </div>

      {/* Scores Preview */}
      {scores && (
        <div className="mt-6 p-4 bg-blue-50 rounded-lg">
          <h3 className="font-medium text-blue-800 mb-2">{t('scorePreview')}</h3>

          {/* Individual Scores */}
          <div className="mb-4">
            <h4 className="font-semibold text-blue-700">{t('individualScores')}</h4>
            <div className="grid grid-cols-2 gap-4">
              {Object.entries(scores.scores.individual).map(([category, score]) => (
                <div key={category} className="text-sm">
                  <div className="font-medium">{t(category)}</div>
                  <Progress value={score} className="h-2 mt-1" />
                </div>
              ))}
            </div>
          </div>

          {/* Composite Scores */}
          <div className="mb-4">
            <h4 className="font-semibold text-blue-700">{t('compositeScores')}</h4>
            <div className="grid grid-cols-2 gap-4">
              {Object.entries(scores.scores.composite).map(([category, score]) => (
                <div key={category} className="text-sm">
                  <div className="font-medium">{t(category)}</div>
                  <Progress value={score} className="h-2 mt-1" />
                </div>
              ))}
            </div>
          </div>

          {/* Metadata */}
          <div className="mb-4">
            <h4 className="font-semibold text-blue-700">{t('metadata')}</h4>
            <div className="grid grid-cols-2 gap-4">
              {Object.entries(scores.scores.metadata).map(([category, value]) => (
                <div key={category} className="text-sm">
                  <div className="font-medium">{t(category)}</div>
                  <Progress value={value} className="h-2 mt-1" />
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      {/* Navigation Controls */}
      <div className="mt-8 flex justify-between items-center">
        {!autoProgress && (
          <button
            onClick={() => setAutoProgress(true)}
            className="px-4 py-2 text-blue-600 hover:text-blue-700"
          >
            {t('resumeAutoProgress')}
          </button>
        )}
        {autoProgress && !isLastStep && (
          <button
            onClick={handleSkipAnimation}
            className="px-4 py-2 text-blue-600 hover:text-blue-700"
          >
            {t('skipAnimation')}
          </button>
        )}
        <button
          onClick={handleNextStep}
          disabled={!isProcessingComplete && isLastStep}
          className={`px-6 py-2 rounded-lg text-white ${
            (!isProcessingComplete && isLastStep)
              ? 'bg-gray-400 cursor-not-allowed'
              : 'bg-blue-600 hover:bg-blue-700'
          }`}
        >
          {isLastStep ? t('viewResults') : t('nextStep')}
        </button>
      </div>
    </div>
  );
};

export default ScoringStage;