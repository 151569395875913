import React, {  startTransition } from "react";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import {  RiQuestionLine } from "react-icons/ri";
import Btn from "Elements/Buttons/Btn";
import { useTranslation } from "react-i18next"; 
import ShowModal from "Elements/Alerts&Modals/Modal";
import useCreate from "utils/Hooks/useCreate";
import { LogoutAPI } from "utils/AxiosUtils/API";

const ProfileNav = ({ modal, setModal }) => {
  const { t } = useTranslation();
  const navigate = useNavigate(); // React Router for navigation
  
  const { mutate } = useCreate(LogoutAPI, false, false, "No", () => {
    Cookies.remove("uatadmin");
    Cookies.remove("ue");
    Cookies.remove("account");
    localStorage.removeItem("account");
    localStorage.removeItem("role");
    navigate("/login"); // React Router navigation
    setModal(false);
  });

  const handleLogout = () => {
    startTransition(() => {
      mutate({});
    });
  };
  

  const handleHideModal = () => {
    startTransition(() => {
      setModal(false);
    });
  };
  return (
    <>

      <ShowModal
        open={modal}
        close={false}
        buttons={
          <>
            <Btn
              title="No"
              onClick={handleHideModal}
              className="btn--no btn-md fw-bold"
            />
            <Btn
              title="Yes"
              onClick={handleLogout}
              className="btn-theme btn-md fw-bold"
              // loading={Number(isLoading)}
            />
          </>
        }
      >
        <div className="remove-box">
          <RiQuestionLine className="icon-box wo-bg" />
          <h5 className="modal-title">{t("Confirmation")}</h5>
          <p>{t("Areyousureyouwanttoproceed?")}</p>
        </div>
      </ShowModal>
    </>
  );
};

export default ProfileNav;
