import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import SuccessHandle from "utils/CustomFunctions/SuccessHandle";
import request from "utils/AxiosUtils";

const useCreate = (
  url,
  updateId,
  path = false,
  message,
  extraFunction,
  notHandler = false,
  responseType = ""
) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const mutation = useMutation({
    mutationFn: async (data) => {
      try {
        setIsLoading(true);
        const response = await request({
          url: updateId
            ? `${url}/${Array.isArray(updateId) ? updateId.join("/") : updateId}`
            : url,
          data,
          method: "post",
          headers: {
            "Content-Type": "multipart/form-data",
          },
          responseType: responseType || "",
        });
        return response;
      } finally {
        setIsLoading(false); // Ensure loading state is reset
      }
    },
    onSuccess: (resData) => {
      if (!notHandler) {
        SuccessHandle(resData, navigate, path, message);
      }
      if (extraFunction) {
        extraFunction(resData);
      }
    },
    onError: (error) => {
      console.error("Error in mutation:", error);
      return error; // You might want to handle the error further
    },
  });

  return { ...mutation, isLoading };
};

export default useCreate;
