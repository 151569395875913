import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ProcessingStage from "./ProcessingStage";
import { Progress } from 'components/ui/progress';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import PrintableReport from './PrintableReport';

const ReportingStage = ({ sessionId, onComplete, onRestart, language, setCompletionReport }) => {
  const { t } = useTranslation();
  const [report, setReport] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [isPrinting, setIsPrinting] = useState(false);
  const printRef = useRef();
  const fetchAttempted = useRef(false);

  const pages = [
    { id: 'summary', title: t('summary') },
    { id: 'careers', title: t('careerPaths') },
    { id: 'action-plan', title: t('actionPlan') },
    { id: 'resources', title: t('resources') }
  ];

  const handlePrint = async () => {
    setIsPrinting(true);
    requestAnimationFrame(() => {
      window.print();
      setIsPrinting(false);
    });
  };

  const fetchReport = useCallback(async () => {
    if (fetchAttempted.current && !error) return;

    setLoading(true);
    setError(null);
    fetchAttempted.current = true;

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/flow/process-report`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ sessionId, language })
      });

      if (!response.ok) {
        throw new Error(t('failedToGenerateReport'));
      }

      const data = await response.json();
      setReport(data.report);
      setCompletionReport(data.report);
    } catch (error) {
      console.error('Error fetching report:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }, [sessionId, t, language]);

  useEffect(() => {
    fetchReport();
  }, [fetchReport]);

  if (loading || !report) {
    return <ProcessingStage message={t('generatingCareerReport')} />;
  }

  if (error) {
    return (
      <div className="text-center py-8">
        <div className="bg-red-50 border border-red-200 rounded-lg p-4 mb-4">
          <h3 className="text-red-800 font-medium mb-2">{t('errorGeneratingReport')}</h3>
          <p className="text-red-600">{error}</p>
        </div>
        <button
          onClick={fetchReport}
          className="px-4 py-2 bg-red-100 text-red-700 rounded-md hover:bg-red-200 transition-colors"
        >
          {t('tryAgain')}
        </button>
      </div>
    );
  }

  if (!report) {
    return (
      <div className="text-center py-8">
        <p>{t('noReportData')}</p>
      </div>
    );
  }

  const renderTopSkills = () => (
    <div className="mb-8">
      <h3 className="text-xl font-semibold mb-4">{t('yourTopSkills')}</h3>
      <div className="grid gap-4 sm:grid-cols-2 lg:grid-cols-3">
        {report.summary.topSkills.primaryStrengths.map((skill, index) => (
          <div key={index} className="p-4 bg-blue-50 rounded">
            <div className="flex justify-between items-center mb-2">
              <h4 className="font-medium">{t(skill.name)}</h4>
              <span className="text-sm text-blue-600">{t(skill.level.level)}</span>
            </div>
            <Progress value={skill.level.score} className="h-2 mb-2" />
            <p className="text-sm text-gray-600">{t(skill.developmentTips)}</p>
          </div>
        ))}
      </div>
    </div>
  );

  const renderCareerPaths = () => (
    <div className="mb-8">
      <h3 className="text-xl font-semibold mb-4">{t('recommendedCareerPaths')}</h3>
      {report.details.recommendations.careers.map((career, index) => (
        <div key={index} className="mb-6 p-6 border rounded-lg">
          <div className="flex flex-col md:flex-row justify-between items-start mb-4">
            <div>
              <h4 className="text-lg font-semibold">{t(career.title)}</h4>
              <p className="text-gray-600">{t(career.description)}</p>
            </div>
            <div className="text-right mt-4 md:mt-0">
              <div className="text-2xl font-bold text-blue-600">{career.matchScore}%</div>
              <div className="text-sm text-gray-500">{t('matchScore')}</div>
            </div>
          </div>
          
          <div className="mt-4">
            <h5 className="font-medium mb-2">{t('careerGrowthPath')}</h5>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
              {Object.entries(career.progression || {}).map(([level, info], idx) => (
                <div key={level} className="p-3 bg-gray-50 rounded">
                  <div className="font-medium">{t(info.role)}</div>
                  <div className="text-sm text-gray-600">{t(info.timeframe)}</div>
                </div>
              ))}
            </div>
          </div>

          <div className="mt-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
            <div className="text-center p-3 bg-green-50 rounded">
              <div className="text-sm text-gray-600">{t('marketDemand')}</div>
              <div className="font-medium text-green-700">{career.marketDemand}</div>
            </div>
            <div className="text-center p-3 bg-blue-50 rounded">
              <div className="text-sm text-gray-600">{t('growthPotential')}</div>
              <div className="font-medium text-blue-700">{career.growthPotential}%</div>
            </div>
            <div className="text-center p-3 bg-purple-50 rounded">
              <div className="text-sm text-gray-600">{t('entryLevelSalary')}</div>
              <div className="font-medium text-purple-700">{career.salary?.entry}</div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );

  const renderActionPlan = () => (
    <div className="mb-8">
      <h3 className="text-xl font-semibold mb-4">{t('recommendedActionPlan')}</h3>
      <div className="space-y-6">
        {[
          { key: 'shortTerm', label: t('next3Months') },
          { key: 'mediumTerm', label: t('next36Months') },
          { key: 'longTerm', label: t('next612Months') }
        ].map(({ key, label }) => (
          <div key={key} className="border-l-4 border-blue-500 pl-4">
            <div className="space-y-3">
              {report.actionPlan[key].map((action, idx) => (
                <div key={idx} className="p-3 bg-gray-50 rounded">
                  <div className="font-medium">{t(action.action)}</div>
                  <p className="text-sm text-gray-600 mt-1">
                    {t('expectedOutcome')}: {t(action.outcome)}
                  </p>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  const renderResources = () => (
    <div className="mb-8">
      <h3 className="text-xl font-semibold mb-4">{t('learningResources')}</h3>
      <div className="grid gap-6">
        {report.resources && report.resources.learning.map((resource, index) => (
          <div key={index} className="border rounded-lg p-4">
            <h4 className="font-medium mb-2">{t(resource.career)}</h4>
            <div className="space-y-3">
              {Object.entries(resource.resources).map(([type, items]) => (
                <div key={type}>
                  <h5 className="text-sm font-medium text-gray-600 mb-1">
                    {t(type)}
                  </h5>
                  <ul className="list-disc list-inside text-sm space-y-1">
                    {items.map((item, idx) => (
                      <li key={idx}>
                        {type === 'books' && (
                          <>
                            <strong>{t(item.title)}</strong> {t('by')} {t(item.author)}
                            - {t('level')}: {t(item.level)} <br />
                            {t('topics')}: {item.topics.map(topic => t(topic)).join(', ')}
                          </>
                        )}
                        {type === 'tutorials' && (
                          <>
                            <strong>{t(item.title)}</strong> {t('on')} {t(item.platform)}
                            - {t(item.duration)} ({t(item.format)})
                          </>
                        )}
                        {type === 'communities' && (
                          <>
                            {t('join')} <strong>{t(item.name)}</strong> {t('on')} {t(item.platform)}
                            ({t(item.type)}, {item.memberCount} {t('members')})
                          </>
                        )}
                        {type === 'mentorship' && (
                          <>
                            <strong>{t(item.name)}</strong> - {t(item.duration)}
                            ({t(item.type)}, {t('focus')}: {item.focus.map(f => t(f)).join(', ')})
                          </>
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  const renderCurrentPage = () => {
    switch (pages[currentPage].id) {
      case 'summary':
        return renderTopSkills();
      case 'careers':
        return renderCareerPaths();
      case 'action-plan':
        return renderActionPlan();
      case 'resources':
        return renderResources();
      default:
        return null;
    }
  };

  const handleContinue = async () => {
    try {
      onComplete?.();
    } catch (error) {
      console.error('Error proceeding to next stage:', error);
      setError(error.message);
    }
  };

  return (
    <div className="max-w-4xl mx-auto space-y-6 px-4 sm:px-6 lg:px-8">
      <div className="bg-white rounded-lg p-6 shadow-sm">
        <div className="flex flex-col md:flex-row justify-between items-center mb-6">
          <h2 className="text-2xl font-bold mb-4 md:mb-0">{t('yourCareerReport')}</h2>
          <div className="text-sm text-gray-500">
            {t('stepOf', { current: currentPage + 1, total: pages.length })}
          </div>
        </div>

        <div className="mb-6">
          <Progress value={(currentPage + 1) * (100 / pages.length)} className="h-2" />
          <div className="mt-2 text-center text-sm font-medium text-gray-600">
            {t(pages[currentPage].title)}
          </div>
        </div>

        {renderCurrentPage()}

        <div className="mt-8 flex flex-col sm:flex-row justify-between items-center space-y-4 sm:space-y-0">
          <button
            onClick={() => setCurrentPage(curr => curr - 1)}
            disabled={currentPage === 0}
            className={`flex items-center px-4 py-2 rounded w-full sm:w-auto ${
              currentPage === 0
                ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
            }`}
          >
            <ChevronLeft className="w-4 h-4 mr-2" />
            {t('previous')}
          </button>

          {currentPage < pages.length - 1 ? (
            <button
              onClick={() => setCurrentPage(curr => curr + 1)}
              className="flex items-center px-4 py-2 rounded bg-blue-600 text-white hover:bg-blue-700 w-full sm:w-auto"
            >
              {t('next')}
              <ChevronRight className="w-4 h-4 ml-2" />
            </button>
          ) : (
            <button
              onClick={handleContinue}
              className="px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors w-full sm:w-auto"
            >
              {t('continue')}
            </button>
          )}
        </div>
      </div>

      <div className="flex flex-col sm:flex-row justify-between space-y-4 sm:space-y-0">
        <button
          onClick={onRestart}
          className="px-6 py-2 bg-gray-100 text-gray-700 rounded hover:bg-gray-200 transition-colors w-full sm:w-auto"
        >
          {t('takeQuestionnaireAgain')}
        </button>
        {/* <button
          onClick={handlePrint}
          className="px-6 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition-colors w-full sm:w-auto"
        >
          {t('downloadReport')}
        </button> */}
      </div>
      
      <div ref={printRef} className={`print-container ${isPrinting ? 'block' : 'hidden'}`}>
        <PrintableReport report={report} />
      </div>
    </div>
  );
};

export default ReportingStage;
