import axios from "axios";
import getCookie from "../CustomFunctions/GetCookie";
console.log(process.env.REACT_APP_API_URL);
const client = axios.create({
  baseURL: process.env.REACT_APP_API_URL||"http://localhost:5000/api",
  headers: {
    Accept: "application/json",
  },
});

const request = async ({ ...options }, router) => {
  client.defaults.headers.common.Authorization = `Bearer ${getCookie("uatadmin")}`;
  const onSuccess = (response) => response;
  const onError = (error) => {
    if (error?.response?.status == 403) {
      router && router.push("/403")
    }
    return error;
  };
  try {
    const response = await client(options);
    return onSuccess(response);
  } catch (error) {
    return onError(error);
  }
};

export default request;
