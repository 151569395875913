import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { Col } from 'reactstrap';
import { YupObject, emailSchema, passwordSchema } from "../utils/Validation/ValidationSchemas";
import { ReactstrapInput } from "../components/ReactstrapFormik";
import useHandleLogin from "../utils/Hooks/Auth/useLogin";
import ShowBox from "../../src/Elements/Alerts&Modals/ShowBox"
const Login = () => {
  const [showBoxMessage, setShowBoxMessage] = useState("");
  const { mutate, isLoading, isError } = useHandleLogin(setShowBoxMessage);
  const handleSubmit = (values) => {
    const formatted = JSON.stringify(values, null, 2) // Indents with 2 spaces
      .replace(/"([^"]+)":/g, '$1:');
    console.log(formatted);
    mutate(values);
  };
  return (
    <section className="log-in-section section-b-space">
      <div className="w-100 container">
        <div className='row'>
          <div className="me-auto col-lg-6 col-xl-5">
            <div className="box-wrapper">
              <ShowBox showBoxMessage={showBoxMessage} />
              <div className="log-in-title">
                <h3>Welcome To Career Questionnaire</h3>
                <h4>Log In Your Account</h4>
              </div>
              <div className="input-box">
                <Formik
                  initialValues={{
                  }}
                  validationSchema={YupObject({
                    email: emailSchema,
                    password: passwordSchema,
                  })}
                  onSubmit={handleSubmit}
                >
                  {({ errors, touched }) => (
                    <Form className="row g-2">
                      <Col sm="12">
                        <Field
                          name="email"
                          type="email"
                          component={ReactstrapInput}
                          className="form-control"
                          id="email"
                          placeholder="Email Address"
                          label="Email Address"
                        />
                      </Col>
                      <Col sm="12">
                        <Field
                          name="password"
                          component={ReactstrapInput}
                          type="password"
                          className="form-control"
                          id="password"
                          placeholder="Password"
                          label="Password"
                        />
                      </Col>
                      <Col sm="12">
                        <div className="forgot-box">
                          {/* Optional forgot password link */}
                        </div>
                      </Col>
                      <Col sm="12">
                        <button type="submit" className="btn btn-primary w-100" disabled={isLoading}>
                          {isLoading ? "Logging in..." : "Login"}
                        </button>
                      </Col>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>




  );
};

export default Login;
