import React from 'react';
import {Card, CardContent, CardHeader, CardTitle} from 'components/ui//card';
import {Alert, AlertDescription} from 'components/ui//alert';
import {AlertCircle, Clock, Activity} from 'lucide-react';
import Button from 'components/ui//Button';
import { Badge } from 'components/ui/badge';

const FlowDebugger = ({flow, onClose}) => {
    const formatDate = (date) => {
        return new Date(date).toLocaleString();
    };

    const getStageProgress = (stage) => {
        const stages = ['questionnaire', 'scoring', 'recommendation', 'reporting'];
        return ((stages.indexOf(stage) + 1) / stages.length * 100).toFixed(0);
    };

    return (
        <div className="space-y-4">
            <div className="flex justify-between items-center">
                <div>
                    <h3 className="text-lg font-semibold">Flow Details</h3>
                    <p className="text-sm text-muted-foreground">Session ID: {flow.sessionId}</p>
                </div>
                <Button variant="outline" onClick={onClose}>
                    Close
                </Button>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                {/* Status Card */}
                <Card>
                    <CardHeader>
                        <CardTitle className="text-sm">Status</CardTitle>
                    </CardHeader>
                    <CardContent>
                        <div className="space-y-2">
                            <div className="flex justify-between items-center">
                                <span className="text-sm text-muted-foreground">Current Stage</span>
                                <Badge>{flow.currentStage}</Badge>
                            </div>
                            <div className="flex justify-between items-center">
                                <span className="text-sm text-muted-foreground">Progress</span>
                                <span>{getStageProgress(flow.currentStage)}%</span>
                            </div>
                            <div className="flex justify-between items-center">
                                <span className="text-sm text-muted-foreground">Status</span>
                                <Badge
                                    variant={flow.status === 'error'
                                    ? 'destructive'
                                    : 'default'}>
                                    {flow.status}
                                </Badge>
                            </div>
                        </div>
                    </CardContent>
                </Card>

                {/* Timing Card */}
                <Card>
                    <CardHeader>
                        <CardTitle className="text-sm">Timing</CardTitle>
                    </CardHeader>
                    <CardContent>
                        <div className="space-y-2">
                            <div className="flex justify-between items-center">
                                <span className="text-sm text-muted-foreground">Started</span>
                                <span>{formatDate(flow.startTime)}</span>
                            </div>
                            <div className="flex justify-between items-center">
                                <span className="text-sm text-muted-foreground">Last Updated</span>
                                <span>{formatDate(flow.lastUpdated)}</span>
                            </div>
                        </div>
                    </CardContent>
                </Card>
            </div>

            {/* Data Card */}
            <Card>
                <CardHeader>
                    <CardTitle className="text-sm">Stage Data</CardTitle>
                </CardHeader>
                <CardContent>
                    <pre className="bg-muted p-4 rounded-lg overflow-auto max-h-96">
            {JSON.stringify(flow.data, null, 2)}
          </pre>
                </CardContent>
            </Card>

            {/* Error Information */}
            {flow.error && (
                <Alert variant="destructive">
                    <AlertCircle className="h-4 w-4"/>
                    <AlertDescription className="mt-1">
                        <p className="font-medium">Error Details</p>
                        <p className="text-sm">{flow.error}</p>
                    </AlertDescription>
                </Alert>
            )}
        </div>
    );
};

export default FlowDebugger;