import { useState, useEffect, useCallback, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';

export const useSession = () => {
  const [sessionId, setSessionId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const initializationPromise = useRef(null);

  const createSession = useCallback(async () => {
    // If there's already an initialization in progress, return that promise
    if (initializationPromise.current) {
      return initializationPromise.current;
    }

    // Create new initialization promise
    initializationPromise.current = (async () => {
      try {
        setIsLoading(true);
        
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/flow/start`,
          {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ sessionId: uuidv4() })
          }
        );

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.error || 'Failed to create session');
        }

        const data = await response.json();

        if (data.success) {
          localStorage.setItem('careerQuestionnaireSessionId', data.sessionId);
          setSessionId(data.sessionId);
          return data.sessionId;
        } else {
          throw new Error(data.error || 'Failed to create session');
        }
      } catch (error) {
        console.error('Error creating session:', error);
        localStorage.removeItem('careerQuestionnaireSessionId');
        setSessionId(null);
        throw error;
      } finally {
        setIsLoading(false);
        initializationPromise.current = null;
      }
    })();

    return initializationPromise.current;
  }, []);

  const checkSession = useCallback(async (storedSessionId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/flow/state/${storedSessionId}`
      );
      
      if (!response.ok) {
        throw new Error('Invalid session');
      }
      
      const data = await response.json();
      
      if (data.success) {
        setSessionId(storedSessionId);
        return true;
      }
      
      throw new Error('Invalid session');
    } catch (error) {
      console.error('Error checking session:', error);
      return false;
    }
  }, []);

  const cleanupSession = useCallback(() => {
    localStorage.removeItem('careerQuestionnaireSessionId');
    setSessionId(null);
    initializationPromise.current = null;
  }, []);

  useEffect(() => {
    let isSubscribed = true;

    const initializeSession = async () => {
      try {
        setIsLoading(true);
        const storedSessionId = localStorage.getItem('careerQuestionnaireSessionId');

        if (storedSessionId) {
          const isValid = await checkSession(storedSessionId);
          if (isValid && isSubscribed) {
            setSessionId(storedSessionId);
            setIsLoading(false);
            return;
          }
        }

        // Only create new session if component is still mounted
        if (isSubscribed) {
          await createSession();
        }
      } catch (error) {
        console.error('Session initialization error:', error);
        if (isSubscribed) {
          cleanupSession();
        }
      } finally {
        if (isSubscribed) {
          setIsLoading(false);
        }
      }
    };

    initializeSession();

    return () => {
      isSubscribed = false;
    };
  }, [createSession, checkSession, cleanupSession]);

  return { 
    sessionId, 
    isLoading, 
    createSession, 
    cleanupSession,
    checkSession 
  };
};