const ConvertPermissionArr = (permissionsData) => {
  let tempName;
  let ansArray = [];
  let num = 0;
  const extraData = [
  ]
  console.log("permissionsData",permissionsData)
  permissionsData?.map((data) => {
    console.log("data",data);
    console.log("data.name",data.name);
    if (tempName == data.name.split(".")[0]) {
      ansArray[num - 1].permissionsArr?.push({ id: data.id, type: data.name.split(".")[1] });
    } else {
      num++;
      tempName = data.name.split(".")[0];
      const existingPermission = ansArray.find(
        (permission) => permission.name === tempName
      );
      if (existingPermission) {
        existingPermission.permissionsArr?.push({ id: data.id, type: data.name.split(".")[1] });
      } 
      ansArray.push({ name: data.name.split(".")[0], permissionsArr: [{ id: data.id, type: data.name.split(".")[1] }] });
     
    }

  });
  ansArray = [...ansArray, ...extraData];
  return ansArray;
};

export default ConvertPermissionArr;
