import React, { useEffect, useState, useCallback } from 'react';
const ProcessingStage = ({ 
    progress, 
    message, 
    stage, 
    onStageComplete 
  }) => {
    const [currentStep, setCurrentStep] = useState(0);
    const [isCompleting, setIsCompleting] = useState(false);
  
    const steps = {
      scoring: [
        { label: 'Analyzing your responses...', duration: 2000 },
        { label: 'Calculating skill scores...', duration: 2000 },
        { label: 'Evaluating career matches...', duration: 2000 },
        { label: 'Preparing recommendations...', duration: 2000 }
      ],
      recommendation: [
        { label: 'Processing career matches...', duration: 2000 },
        { label: 'Generating learning paths...', duration: 2000 },
        { label: 'Analyzing industry trends...', duration: 2000 },
        { label: 'Finalizing recommendations...', duration: 2000 }
      ],
      reporting: [
        { label: 'Compiling your results...', duration: 2000 },
        { label: 'Generating detailed report...', duration: 2000 },
        { label: 'Preparing final insights...', duration: 2000 },
        { label: 'Completing analysis...', duration: 2000 }
      ]
    };
  
    const currentSteps = steps[stage] || steps.scoring;
  
    useEffect(() => {
      if (!isCompleting && progress < 100) {
        const timer = setTimeout(() => {
          if (currentStep < currentSteps.length - 1) {
            setCurrentStep(prev => prev + 1);
          } else if (progress >= 100) {
            setIsCompleting(true);
            onStageComplete?.();
          }
        }, currentSteps[currentStep]?.duration || 2000);
  
        return () => clearTimeout(timer);
      }
    }, [currentStep, progress, stage, isCompleting]);
  
    return (
      <div className="max-w-xl mx-auto text-center py-8">
        {/* Stage Header */}
        <h2 className="text-2xl font-semibold mb-6">
          {message || 'Processing Your Results'}
        </h2>
  
        {/* Progress Animation */}
        <div className="relative mb-8">
          <div className="flex justify-center mb-6">
            <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-blue-500"></div>
          </div>
  
          {/* Current Step Indicator */}
          <div className="mb-6">
            <p className="text-lg font-medium text-gray-700">
              {currentSteps[currentStep]?.label}
            </p>
            <p className="text-sm text-gray-500 mt-2">
              Step {currentStep + 1} of {currentSteps.length}
            </p>
          </div>
  
          {/* Progress Bar */}
          <div className="w-full bg-gray-200 rounded-full h-2 mb-4">
            <div
              className="bg-blue-600 h-2 rounded-full transition-all duration-500"
              style={{ width: `${progress}%` }}
            />
          </div>
          <div className="flex justify-between text-sm text-gray-500">
            <span>Progress: {progress}%</span>
            <span>{isCompleting ? 'Completing...' : 'Processing...'}</span>
          </div>
        </div>
  
        {/* Progress Steps */}
        <div className="space-y-4">
          {currentSteps.map((step, index) => (
            <div
              key={index}
              className={`flex items-center ${
                index <= currentStep ? 'text-blue-600' : 'text-gray-400'
              }`}
            >
              <div className="flex-1 h-px bg-current"></div>
              <div className={`
                w-8 h-8 mx-2 rounded-full flex items-center justify-center
                ${index <= currentStep ? 'bg-blue-100' : 'bg-gray-100'}
                ${index === currentStep ? 'ring-2 ring-blue-300' : ''}
              `}>
                {index < currentStep ? (
                  <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
                    <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd"/>
                  </svg>
                ) : (
                  <span>{index + 1}</span>
                )}
              </div>
              <div className="flex-1 h-px bg-current"></div>
            </div>
          ))}
        </div>
  
        {/* Tips or Information */}
        <div className="mt-8 p-4 bg-blue-50 rounded-lg">
          <h3 className="font-medium text-blue-800 mb-2">
            Did you know?
          </h3>
          <p className="text-sm text-blue-600">
            {stage === 'scoring' && 'We analyze over 50 different factors to determine your ideal career path.'}
            {stage === 'recommendation' && 'Our recommendations are based on real-world industry data and success patterns.'}
            {stage === 'reporting' && 'Your personalized report includes detailed insights and actionable next steps.'}
          </p>
        </div>
      </div>
    );
  };
  export default ProcessingStage;