
import React, { useContext } from "react";
import { BiCheckShield, BiError } from "react-icons/bi";
import { motion } from "framer-motion";
import I18NextContext from "../../Helpers/I18NextContext";
import { useTranslation } from "react-i18next";

const ShowBox = ({ showBoxMessage }) => {
  const { t, i18n } = useTranslation();
  if (!showBoxMessage) return null;
  return (
    <div className={showBoxMessage ? "error-box" : "success-box"}>
     {showBoxMessage ? <BiError/> : <BiCheckShield />}
      <div>
        <h4>{showBoxMessage ? t("ThereWasAProblem") : t("Success")} </h4>
        <p>{t(showBoxMessage)}</p>
      </div>
    </div>
   

  );
};

export default ShowBox;
