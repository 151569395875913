import React, { useContext } from "react";
import { ErrorMessage } from "formik";
import { 
  FormFeedback, 
  FormGroup, 
  Input, 
  InputGroup, 
  Label, 
  InputGroupText 
} from "reactstrap";
import SettingContext from "../../Helpers/SettingContext";
import I18NextContext from "../../Helpers/I18NextContext";
import { handleModifier } from "../../utils/Validation/ModifiedErrorMessage";
import { useTranslation } from "react-i18next";

const ReactstrapFormikInput = ({
  field: { ...fields },
  form: { touched, errors },
  ...props
}) => {
  const { t, i18n } = useTranslation();
  const { currencySymbol } = useContext(SettingContext);

  const isInvalid = Boolean(touched[fields.name] && errors[fields.name]);
  const isValid = Boolean(touched[fields.name] && !errors[fields.name]);

  const handleInputValidation = (e) => {
    if (props.min && props.max) {
      const value = e.target.value;
      if (value > 100) e.target.value = 100;
      if (value < 0) e.target.value = 0;
    }
  };

  const renderFormFeedback = () =>
    isInvalid && (
      <FormFeedback>
        {t(props.title ?? props.name)} {t("IsRequired")}
      </FormFeedback>
    );

  const renderHelperText = () =>
    props?.helpertext && <p className="help-text">{props.helpertext}</p>;

  return (
    <>
      {props.label ? (
        <FormGroup floating>
          <Input
            {...props}
            {...fields}
            invalid={isInvalid}
            valid={isValid}
            autoComplete="off"
          />
          <Label htmlFor={props.id}>{t(props.label)}</Label>
          {isInvalid && (
            <FormFeedback>
              {t(handleModifier(errors[fields.name]).replace(/\s+/g, ""))}
            </FormFeedback>
          )}
        </FormGroup>
      ) : props.inputaddon ? (
        <InputGroup>
          {!props.postprefix && (
            <InputGroupText>
              {props.prefixvalue || currencySymbol}
            </InputGroupText>
          )}
          <Input
            {...props}
            {...fields}
            disabled={!!props.disable}
            invalid={isInvalid}
            valid={isValid}
            autoComplete="off"
            readOnly={!!props.readOnly}
            onInput={handleInputValidation}
          />
          {props.postprefix && (
            <InputGroupText>{props.postprefix}</InputGroupText>
          )}
          {renderFormFeedback()}
          {props.errormsg && (
            <ErrorMessage
              name={fields.name}
              render={(msg) => (
                <div className="invalid-feedback d-block">
                  {t(props.errormsg)} {t("IsRequired")}
                </div>
              )}
            />
          )}
          {renderHelperText()}
        </InputGroup>
      ) : props.type === "color" ? (
        <div className="color-box">
          <Input
            {...props}
            {...fields}
            disabled={!!props.disable}
            invalid={isInvalid}
            valid={isValid}
            autoComplete="off"
          />
          {isInvalid && (
            <FormFeedback>
              {t(handleModifier(errors[fields.name]))}
            </FormFeedback>
          )}
          <h6>{fields.value}</h6>
        </div>
      ) : (
        <>
          <Input
            {...props}
            {...fields}
            disabled={!!props.disable}
            invalid={isInvalid}
            valid={isValid}
            autoComplete="off"
            onInput={handleInputValidation}
          />
          {renderHelperText()}
          {renderFormFeedback()}
          {props.errormsg && (
            <ErrorMessage
              name={fields.name}
              render={(msg) => (
                <div className="invalid-feedback d-block">
                  {t(props.errormsg)} {t("IsRequired")}
                </div>
              )}
            />
          )}
        </>
      )}
    </>
  );
};

export default ReactstrapFormikInput;
