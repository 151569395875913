import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ThemeProvider } from './components/ui/theme-provider';
import { SocketProvider } from './contexts/SocketContext';
import I18NextProvider from './Helpers/I18NextContext/I18NextProvider';
import SettingProvider from './Helpers/SettingContext/SettingProvider';
import Home from './pages/Home';
import Login from './pages/Login';
import Results from './components/Results';
import './assets/scss/app.scss';
import FlowManagement from 'components/admin/FlowManagement';
import CompletionStage from 'components/CareerQuestionnaire/CompletionStage';
import ProtectedRoute from 'routes/ProtectedRoute';
import AdminLayout from './Layout';
import DashboardRoute from 'routes/DashboardRoute';
import AdminDashboard from 'pages/AdminDashboard';
import { I18nextProvider, useTranslation } from 'react-i18next';
import i18next from './i18n';
import ProfilePage from 'pages/Profile';

export const i18n = i18next;
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 2,
      staleTime: 30000,
      refetchOnWindowFocus: false
    }
  }
});

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <I18nextProvider i18n={i18n}>
        <SettingProvider>
          <ThemeProvider defaultTheme="dark" storageKey="theme">
            <SocketProvider>
                  <Routes>
                    {/* Public Routes */}
                    <Route path="/" element={<Home />} />
                    <Route path="/completedStage" element={<CompletionStage />} />
                    <Route path="/results" element={<Results />} />
                    <Route path="/admin/login" element={
                      <DashboardRoute>
                        <Login />
                      </DashboardRoute>
                    } />

                    {/* Admin Routes */}
                    <Route path="/admin" element={
                      <ProtectedRoute>
                        <AdminLayout />
                      </ProtectedRoute>
                    }>
                      <Route index element={<Navigate to="/admin/dashboard" replace />} />
                      <Route path="dashboard" element={<AdminDashboard />} />
                      <Route path="profile" element={<ProfilePage />} />
                    </Route>

                    {/* Catch all route */}
                    <Route path="*" element={<Navigate to="/" replace />} />
                  </Routes>
            </SocketProvider>
          </ThemeProvider>
        </SettingProvider>
      </I18nextProvider>
    </QueryClientProvider>
  );
};

export default App;