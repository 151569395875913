import React from 'react';
import { Card, CardContent } from '../../ui/card';
import { Badge } from '../../ui/badge';

export const FlowList = ({ flows, onSelect, selectedId }) => {
  return (
    <div className="space-y-4">
      {flows.map((flow) => (
        <Card 
          key={flow.sessionId}
          className={`cursor-pointer transition-all ${
            selectedId === flow.sessionId ? 'ring-2 ring-primary' : ''
          }`}
          onClick={() => onSelect(flow)}
        >
          <CardContent className="p-4">
            <div className="flex justify-between items-center">
              <div>
                <p className="font-medium">{flow.sessionId}</p>
                <p className="text-sm text-muted-foreground">
                  Stage: {flow.currentStage}
                </p>
              </div>
              <Badge variant={flow.status === 'error' ? 'destructive' : 'default'}>
                {flow.status}
              </Badge>
            </div>
          </CardContent>
        </Card>
      ))}
    </div>
  );
};