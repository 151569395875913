import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '../components/ui/Button';
import CareerQuestionnaire from 'components/CareerQuestionnaire';



const Home = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div >
      <CareerQuestionnaire/>
    </div>
  );
};

export default Home;