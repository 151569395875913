import React, { useContext } from "react";
import { Button } from "reactstrap";
import I18NextContext from "../../Helpers/I18NextContext";
import { useTranslation } from "react-i18next";

const Btn = (props) => {
  const { t, i18n } = useTranslation();
  return (
    <Button {...props}>
      <div className={`d-flex align-items-center position-relative${props.loading ? " spinning" : ""}`}>
        {props.children}
        {t(props.title)}
      </div>
    </Button>
  );
};
export default Btn;
