import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import SettingContext from '.';
import { settingReducer } from '../../utils/AllReducers';
import request from '../../utils/AxiosUtils';
import { updateSetting } from '../../utils/AxiosUtils/API';
const SettingProvider = (props) => {
    const [currencySymbol, setCurrencySymbol] = useState('');
    const [settingObj, setSettingObj] = useState({});
    const [searchSidebarMenu, setSearchSidebarMenu] = useState([]);
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [state, dispatch] = useReducer(settingReducer, {
        setFavicon: "",
        setLogo: "",
        setResponsiveImage: "",
        setTitle: "",
        setTagline: "",
        isMultiVendor: false,
        setDelivery: {},
        setCopyRight: "",
        setDarkLight: "",
        setDarkLogo: "",
        setLightLogo: "",
        setTinyLogo: ""
    });

    const { data, isLoading, refetch } = useQuery({
        queryKey: ['settings'], // Corrected query key
        queryFn: () => request({ url: updateSetting }), // Corrected fetch function
        enabled: false,
        refetchOnWindowFocus: false,
        select: (res) => res?.data,
    });

    useEffect(() => {
        refetch(); // Trigger refetch on mount
    }, [refetch]);

    useEffect(() => {
        if (data) {
            console.log(data?.values);
            setSettingObj(data?.values);
        }
    }, [data]);
    useEffect(() => {
        if (!isLoading) {
            setCurrencySymbol(data?.values?.general?.default_currency?.symbol);
        }
        if (data) {
            // data?.values?.general['mode'] == "dark-only" ? document.body.classList.add("dark-only") : document.body.classList.remove("dark-only")
            // data?.values?.general['admin_site_language_direction'] == 'ltr' ? (document.documentElement.dir = "ltr") : (document.documentElement.dir = "rtl");
            dispatch({
                type: "SETTINGIMAGE",
                darkLogo: data?.values?.setDarkLogo,
                lightLogo: data?.values?.setLightLogo,
                tinyLogo: data?.values?.setTinyLogo,
                favicon: data?.values?.setFavicon,

                title: data?.values?.setTitle,
                tagline: data?.values?.general?.site_tagline,
                copyRight: data?.values?.general?.copyright,
                darkLight: data?.values?.general?.mode,
            })
        }
    }, [isLoading])

    return (
        <SettingContext.Provider value={{
            ...props,
            sidebarOpen,
            setSidebarOpen,
            currencySymbol,
            setCurrencySymbol,
            state,
            dispatch,
            searchSidebarMenu,
            setSearchSidebarMenu,
            // convertCurrency,
            settingObj,
            setSettingObj
        }}>
            {props.children}
        </SettingContext.Provider>
    );
};

export default SettingProvider;
