import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import useAuth from 'utils/Hooks/Auth/useAuth';

const DashboardRoute = ({ children }) => {
  const isAuthenticated = useAuth();
  const location = useLocation();

  if (isAuthenticated) {
    // Redirect to dashboard if user is already authenticated
    return <Navigate to="/admin/dashboard" replace />;
  }

  return children;
};

export default DashboardRoute;