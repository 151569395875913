import { useContext, useEffect, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import PropTypes from 'prop-types';
import { useTranslation } from "react-i18next";

const ShowModal = ({ open, buttons, title, close, modalAttr, setModal, ...props }) => {
  const [isOpen, setIsOpen] = useState(open);
  const { t, i18n } = useTranslation();
  useEffect(() => {
    setIsOpen(open);
  }, [open]);
  return (
    <Modal id="staticBackdrop" isOpen={isOpen} centered className={`theme-modal theme-form ${modalAttr?.className}`} toggle={() => {
      setIsOpen(false); setModal && setModal(false);
    }}>
      {close && (
        <ModalHeader
          toggle={() => {
            setIsOpen(false); setModal && setModal(false);
          }}>
          {title && (title === "success" ? t("success") : title === "fail" ? t("Oops!") : t(title))}
        </ModalHeader>
      )}
      {!close && title && <ModalHeader>{title === "success" ? "success" : title === "fail" ? "Oops!" : t(title)}</ModalHeader>}
      <ModalBody>{props.children}</ModalBody>
      {(buttons) && (
        <ModalFooter>
          <div className="button-box">{buttons}</div>
        </ModalFooter>
      )}
    </Modal>
  );
};
ShowModal.prototype = {
  open:false,
  close:true
};
export default ShowModal;
