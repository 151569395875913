
import Dashboard from 'components/admin/Dashboard'
import FlowManagement from 'components/admin/FlowManagement'
import MonitoringDashboard from 'components/admin/MonitoringDashboard'
import React from 'react'

export default function AdminDashboard() {
  return (
    <>
      <Dashboard/>
    </>
 
  )
}
