import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate, useLocation } from "react-router-dom";
import request from "utils/AxiosUtils";
import { ToastNotification } from "utils/CustomFunctions/ToastNotification";

/**
 * Custom hook for handling update operations with React Query
 * @param {string} url - The base URL for the API endpoint
 * @param {string|string[]|null} updateId - ID or array of IDs to update (optional)
 * @param {string} path - Redirect path after successful update
 * @param {string} message - Success message to display
 * @param {Function} extraFunction - Additional function to execute after success
 * @param {string} method - HTTP method (defaults to "put")
 * @param {Object} headers - Custom headers for the request
 * @param {string} queryKey - Query key to invalidate after successful update
 */
const useUpdate = (url, updateId = null, path, message, extraFunction, method = "put", headers, queryKey) => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();

  // Success handler function
  const handleSuccess = (responseData) => {
    console.log("toasting");
    // Show success notification
    ToastNotification("success", message || "Updated Successfully");

    // Execute extra function if provided
    if (extraFunction && typeof extraFunction === "function") {
      extraFunction(responseData);
    }

    // Invalidate queries if queryKey is provided
    if (queryKey) {
      queryClient.invalidateQueries(queryKey);
    }

    // Redirect if path is provided
    if (path) {
      const redirectPath = path.startsWith('/') ? path : `/${path}`;
      if (location.pathname !== redirectPath) {
        navigate(redirectPath);
      }
    }
  };

  // Error handler function
  const handleError = (error) => {
    const errorMessage = error?.response?.data?.message || "Update Failed";
    ToastNotification("error", errorMessage);
  };

  // Create mutation
  const mutation = useMutation({
    mutationFn: (data) => {
      // Construct the URL based on whether updateId is provided
      let endpoint = url;
      
      if (updateId) {
        // If updateId is an array, join with "/"
        if (Array.isArray(updateId)) {
          endpoint = `${url}/${updateId.join("/")}`;
        } else {
          // If updateId is a single value, append it
          endpoint = `${url}/${updateId}`;
        }
      }

      // Return the request promise
      return request({
        url: endpoint,
        method: method,
        data: data,
        headers: headers || {
          "Content-Type": "application/json"
        }
      });
    },
    onSuccess: (response) => {
      handleSuccess(response.data);
    },
    onError: handleError
  });

  // Return mutation object with additional helper methods
  return {
    ...mutation,
    updateData: mutation.mutate,
    updateDataAsync: mutation.mutateAsync,
    reset: mutation.reset
  };
};

export default useUpdate;

// Example usage:
/*
// With ID
const updateUserMutation = useUpdate(
  "/api/users",          // URL
  userId,                // Update ID
  "/users",             // Redirect path
  "User updated!",      // Success message
);

// Without ID (for endpoints that don't require ID)
const updateSettingsMutation = useUpdate(
  "/api/settings",      // URL
  null,                 // No Update ID
  "/settings",         // Redirect path
  "Settings updated!", // Success message
);

// With array of IDs
const updateBulkMutation = useUpdate(
  "/api/items",         // URL
  ["id1", "id2"],      // Array of Update IDs
  "/items",            // Redirect path
  "Items updated!",    // Success message
);
*/