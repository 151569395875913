import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Home, Settings, ChevronLeft, ChevronRight, Moon, Sun, Globe } from 'lucide-react';
import { useTranslation } from 'react-i18next';

const navItems = [
  { text: "Dashboard",link:"admin/dashboard", icon: <Home className="w-5 h-5" /> },
];

const Sidebar = ({ user, drawerWidth, isSidebarOpen, setIsSidebarOpen, isNonMobile, darkMode }) => {
  const { pathname } = useLocation();
  const [active, setActive] = useState("");
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    setActive(pathname.substring(1));
  }, [pathname]);


  return (
    <nav className="h-full">
      {isSidebarOpen && (
        <div className={`
          z-10 fixed top-0 left-0 h-full dark:bg-gray-800 
          border-r border-gray-200 dark:border-gray-700
          transition-all duration-300 ease-in-out
          ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'}
          ${isNonMobile ? 'border-none' : 'border-r-2'}
          ${darkMode ? " bg-gray-900 text-white" : "bg-white text-black"}
        `}
          style={{ width: drawerWidth }}
        >
          <div className="w-full">
            {/* Header */}
            <div className="flex items-center justify-between px-6 py-4">
              <div className="flex items-center space-x-2">
                <span className="text-xl font-bold text-gray-800 dark:text-white">
                  Career Questionnaire
                </span>
              </div>
              <div className="flex items-center space-x-4">
                {!isNonMobile && (
                  <button
                    onClick={() => setIsSidebarOpen(false)}
                    className="p-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700"
                  >
                    <ChevronLeft className="w-5 h-5" />
                  </button>
                )}
              </div>
            </div>

            {/* Navigation Items */}
            <ul className="px-4 py-2">
              {navItems.map(({ text,link, icon }) => {
                if (!icon) {
                  return (
                    <li key={text} className={`px-4 py-3 text-sm font-medium ${darkMode ? "   text-gray-500 dark:text-gray-400" : " text-gray-700 hover:bg-gray-100"} `}>
                      {t(text)}
                    </li>
                  );
                }

                const lcText = text.toLowerCase();
                const isActive = active === lcText;

                return (
                  <li key={text}>
                    <button
                      onClick={() => {
                        navigate(`/${link}`);
                        setActive(lcText);
                      }}
                      className={`
    w-full flex items-center px-4 py-3 rounded-lg
    transition-colors duration-200
    ${isActive
                          ? 'bg-blue-50 text-blue-600'
                          : `text-gray-600  ${darkMode ? 'text-gray-200 hover:bg-gray-600' : 'text-gray-700 hover:bg-gray-100'
                          }`
                        }
  `}
                    >

                      <span className="ml-2">{icon}</span>
                      <span className="ml-3">{t(text)}</span>
                      {isActive && <ChevronRight className="w-5 h-5 ml-auto" />}
                    </button>
                  </li>
                );
              })}
            </ul>
          </div>



          {/* User Profile */}
          <div className="absolute bottom-0 left-0 right-0 p-4 border-t border-gray-200 dark:border-gray-700">
            <div className="flex items-center space-x-4">
              <img
                src={user?.profileImage || '/placeholder-avatar.png'}
                alt="profile"
                className="w-10 h-10 rounded-full object-cover"
              />
              <div className="flex-1 min-w-0">
                <p className="text-sm font-medium text-gray-900 dark:text-white truncate">
                  {user?.name}
                </p>
                <p className="text-sm text-gray-500 dark:text-gray-400 truncate">
                  {user?.occupation}
                </p>
              </div>
              <button className="p-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700">
                <Settings className="w-5 h-5 text-gray-500 dark:text-gray-400" />
              </button>
            </div>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Sidebar;
