import { useSocket } from 'contexts/SocketContext';
import { useState, useEffect, useCallback, useRef } from 'react';


export const useFlowState = (sessionId) => {
    const socket = useSocket();
    const [flowState, setFlowState] = useState({
      stage: 'questionnaire',
      status: 'active',
      progress: 0
    });
    
    // Use ref to track if initial state has been fetched
    const initialFetchDone = useRef(false);
  
    // Memoize the update handler
    const handleFlowUpdate = useCallback((update) => {
      console.log("update",update);
      console.log("update status",update.status);
      console.log("update status",update.stage);
      if(update.stage == "reporting" && update.status == "completed"){
          update.stage = "completed";
      }
      console.log("after update",update);
      setFlowState(prev => ({
        ...prev,
        ...update
      }));
    }, []);
  
    useEffect(() => {
      if (!sessionId || !socket) return;
  
      const fetchInitialState = async () => {
        if (initialFetchDone.current) return;
        
        try {
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/flow/state/${sessionId}`
          );
          if (response.ok) {
            const state = await response.json();
            setFlowState(state);
          }
          initialFetchDone.current = true;
        } catch (error) {
          console.error('Error fetching flow state:', error);
        }
      };
  
      // Join flow room and set up listeners
      socket.emit('joinFlow', sessionId);
      socket.on(`flow:${sessionId}`, handleFlowUpdate);
      
      // Fetch initial state only once
      fetchInitialState();
  
      return () => {
        socket.off(`flow:${sessionId}`, handleFlowUpdate);
      };
    }, [sessionId, socket, handleFlowUpdate]);
  
    return flowState;
  };
  