import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';

const useAuth = () => {
  const [cookies, , removeCookie] = useCookies(['uatadmin', 'account']);
  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    return Boolean(cookies.uatadmin);
  });

  useEffect(() => {
    const checkAuth = () => {
      // Check if 'uatadmin' token exists and hasn't expired
      const token = cookies.uatadmin;
      if (!token) {
        setIsAuthenticated(false);
        return;
      }

      // Parse expiration date from cookie (assuming expiry is set in the cookie itself)
      const expiryDate = new Date(localStorage.getItem('authExpiry'));
      const now = new Date();

      if (now >= expiryDate) {
        logout();
      } else {
        setIsAuthenticated(true);
        // Set a timeout to automatically log out when the token expires
        const timeout = expiryDate.getTime() - now.getTime();
        const logoutTimer = setTimeout(logout, timeout);

        return () => clearTimeout(logoutTimer); // Clear timeout on unmount
      }
    };

    const logout = () => {
      setIsAuthenticated(false);
      removeCookie('uatadmin', { path: '/' });
      removeCookie('account', { path: '/' });
      localStorage.removeItem('account');
      localStorage.removeItem('authExpiry');
    };

    checkAuth();

    // Listen for storage changes (e.g., logout in another tab)
    window.addEventListener('storage', checkAuth);
    return () => window.removeEventListener('storage', checkAuth);
  }, [cookies, removeCookie]);

  return isAuthenticated;
};

export default useAuth;
