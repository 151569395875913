import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import request from "../../AxiosUtils";
import { login } from "../../AxiosUtils/API";
import { useState } from "react";

const useHandleLogin = (setShowBoxMessage) => {
  const [, setCookie] = useCookies(["ue", "uatadmin", "account"]);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const loginMutation = useMutation({
    mutationFn: (data) => {
      setIsLoading(true);
      return request({
        url: login,
        method: "post",
        data,
      });
    },
    onSuccess: (responseData) => {
      setIsLoading(false);
      const { data, status } = responseData;
      
      if (status === 200) {
        const accessToken = data.access_token;
        const expiryDate = new Date(Date.now() + 24 * 60 * 6000); // 24 hours expiration

        setCookie("account", JSON.stringify(data), { path: "/" });
        setCookie("uatadmin", accessToken, { 
          path: "/", 
          expires: expiryDate 
        });

        localStorage.setItem("account", JSON.stringify(data));
        localStorage.setItem("authExpiry", expiryDate.toISOString());
        window.location.href = '/admin/dashboard';
        navigate("/admin/dashboard");
      } else {
        setShowBoxMessage(responseData.response?.data?.message || "An unexpected error occurred");
      }
    },
    onError: (error) => {
      setIsLoading(false);
      setShowBoxMessage(error.response?.data?.message || "An error occurred during login");
    },
  });

  return { ...loginMutation, isLoading };
};

export default useHandleLogin;
